<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field label="Emailadres" name="login" prepend-icon="person" type="text" v-model="input.email" v-on:keyup.enter="login()"/>
                <v-text-field id="password" label="Password" name="password" prepend-icon="lock" type="password" v-model="input.password" v-on:keyup.enter="login()"/>
                <v-alert :value="emailPassWrongAlert" type="error" transition="fade-transition">De gebruikersnaam en/of wachtwoord is incorrect.</v-alert>
                <v-alert :value="emailPassEmptyAlert" type="error" transition="fade-transition">Zowel gebruikersnaam als wachtwoord moeten ingevuld zijn.</v-alert>
                <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition">Er deed zich een onverwachte fout voor. Probeer het later opnieuw.</v-alert>
                <v-alert :value="networkErrorAlert" type="warning" transition="fade-transition">Er deed zich een netwerkfout voor. Probeer het later opnieuw.</v-alert>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary" value="true" form="loginForm" v-on:click="login()">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      emailPassEmptyAlert: false,
      emailPassWrongAlert: false,
      unknownErrorAlert: false,
      networkErrorAlert: false,
      input: {
        email: "",
        password: ""
      }
    };
  },
  beforeCreate: function() {
    if (this.$session.exists()) {
      // Actually check token in the future!!
      this.$router.push("/overview");
    }
  },
  methods: {
    login() {
      this.emailPassWrongAlert = false;
      this.unknownErrorAlert = false;
      this.networkErrorAlert = false;

      if (this.input.email != "" && this.input.password != "") {
        // If email and password are not empty
        // Reset empty alert
        this.emailPassEmptyAlert = false;

        // Initialize parameters for request
        const params = {
          email: this.input.email,
          password: this.input.password
        };

        // Execute POST request to login email
        axios
          .post(this.$API_LINK+"/login", params)
          .then(res => {
            if (res.status == 200) {
              // If login was successful
              // Store authToken & reroute to overview page
              this.$session.start();
              this.$session.set("authToken", res.data.token);
              if(res.data.role == 2){
                this.$session.set("role", 2);
              } else if (res.data.role == 1) {
                this.$session.set("role", 1);
              } else {
                this.$session.set("role", 0);
              }
              this.$router.push("/overview");
            } else if (res.status == 401) {
              this.emailPassWrongAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.emailPassWrongAlert = false
              },10000)
            }
          })
          .catch(error => {
            if (!error.response) {
              // Network error
              this.networkErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.networkErrorAlert = false
              },10000)
            } else if (error.response.status == 401) {
              this.emailPassWrongAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.emailPassWrongAlert = false
              },10000)
            } else {
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.unknownErrorAlert = false
              },10000)
            }
          });
      } else {
        // If email and password are empty
        this.emailPassEmptyAlert = true;
        // Automatically close alert after 10s
        setTimeout(()=>{
          this.emailPassEmptyAlert = false
        },10000)
      }
    }
  }
};
</script>
