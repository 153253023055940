import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import VueSession from "vue-session";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import VueMask from 'v-mask'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLevelDownAlt, faMinus, faPlus, faUpload, faKey, faFolderPlus, faFileCsv, faEdit, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const { version } = require('../package.json');
import HTML_TEMPLATE from './assets/htmltemplate.js'
import JSZip from 'jszip'

library.add(faPlus, faMinus, faUpload, faLevelDownAlt, faKey, faFolderPlus, faFileCsv, faEdit, faTrash, faPrint)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
//Supress warnings
Vue.config.silent = true

Vue.prototype.$API_LINK = "https://tctapi.teneo-iot.nl"
Vue.prototype.$VERSION = version
Vue.prototype.$HTML_TEMPLATE = HTML_TEMPLATE.HTML_TEMPLATE

Vue.use(VueSession);
Vue.use(VueMask)
Vue.component("downloadExcel", JsonExcel)

new Vue({
  el: '#app',
  template: '<App/>',
  components: { App },
  router,
  vuetify,
  axios,

  render: function (h) {
    return h(App);
  }
}).$mount('#app')
