<template>
  <div id="overview">
    <v-container fluid fill-height>
      
      <v-layout align-center justify-center text-xs-center>
        <div text-xs-center style="width:100%">
          <v-layout align-end justify-end>
            <v-btn color="primary" @click="insertUser()" dark class="mb-2" justify-right>Toevoegen</v-btn>
          </v-layout>
          <v-alert :value="succesAlert" type="success" transition="fade-transition" dismissible>
            Bewerking succesvol afgerond
          </v-alert>
          <v-data-table :headers="headers" :items="users" multi-sort class="elevation-1">
            <template v-slot:[`item.action`]="user" v-if="$session.get('role') == 1">
              <v-icon small class="mr-2" @click="editUser(user)">edit</v-icon>
              <v-icon small class="mr-2" @click="deleteItem(user)">delete</v-icon>
              <v-icon small class="mr-2" :color="user.item.active ? 'green' : 'red'" @click="disableUser(user)">
                {{user.item.active ? 'mdi-account':'mdi-account-off'}} </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-layout>
    </v-container>

    <v-dialog v-model="editDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>Gebruiker wijzigen</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition">
                Er deed zich een onverwachte fout voor. Probeer het later opnieuw.
                </v-alert>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="editedUser.username" :rules="euUsernameRules" label="Gebruikersnaam" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="editedUser.email" :rules="euEmailRules" label="Emailadres" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="editedUser.password" :rules="euPasswordRules" label="Wachtwoord" type="password"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="editedUser.role" :items="adminItems" :rules="[v => !!v || 'Keuze is verplicht']" label="Rol" required></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="processEditUser()">Bijwerken</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="insertDialog" width="500">
      <v-form ref="insertForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>Gebruiker toevoegen</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition">
                  Er deed zich een onverwachte fout voor. Probeer het later opnieuw.
                </v-alert>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="insertedUser.username" :rules="iuUsernameRules" label="Gebruikersnaam" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="insertedUser.email" :rules="iuEmailRules" label="Emailadres" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="insertedUser.password" :rules="iuPasswordRules" label="Wachtwoord" type="password"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select v-model="insertedUser.role" :items="adminItems" :rules="[v => !!v || 'Keuze is verplicht']" label="Rol" required></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" color="primary" text @click="validate()">Toevoegen</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      unknownErrorAlert: false,
      notFoundAlert: false,
      notAdminAlert: false,
      succesAlert: false,
      role: false,
      valid: true,
      headers: [
        { text: "ID", value: "id", align: "left" },
        { text: "Gebruikersnaam", value: "username"},
        { text: "Emailadres", value: "email" },
        { text: "Type", value: "role" },
        { text: "Acties", value: "action", sortable: false }
      ],
      users: [],
      tempUsers: [],
      insertDialog: false,
      editDialog: false,
      item: null,
      insertedUser: {
        id: 0,
        username: null,
        email: null,
        password: null,
        active: 0,
        role: 0
      },
      editedUser: {
        id: 0,
        username: null,
        email: null,
        password: null,
        active: 0,
        role: 0
      },
      accountEnabled: false,
      adminItems: ["Gebruiker", "Ontwikkelaar", "Administrator"],
      iuUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      iuEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /.+@.+/.test(value) || "Emailadres is ongeldig"
      ],
      iuPasswordRules: [
        value => !!value || "Wachtwoord is verplicht",
        value =>
          (value && value.length >= 5) ||
          "Wachtwoord moet uit ten minste 5 tekens bestaan"
      ],
      euUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      euEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value) || "Emailadres is ongeldig"
      ],
      euPasswordRules: [
        value =>
          (value && value.length >= 5) ||
          "Wachtwoord moet uit ten minste 5 tekens bestaan"
      ]
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get users on initial page load
    this.$nextTick(this.getUsers());

    if (this.$session.get("role") != 1) { //role
      // Remove userid & actions columns if user isn't admin
      this.headers.pop();
      //   this.headers.splice(-2, 2);
    }
  },
  methods: {
    processInsertUser: function() {

      this.succesAlert = false;
        // Check rol type
      if (this.insertedUser.role == "Ontwikkelaar"){
        this.insertedUser.role = 2;
      }  else if (this.insertedUser.role == "Administrator") {
        this.insertedUser.role = 1;
      } else {
        this.insertedUser.role = 0;
      }

      // Post values to api
      axios
        .post(this.$API_LINK+"/store/user", this.insertedUser)
        .then(res => {
          if (res.status == 200) {
            this.insertDialog = false;
            this.succesAlert = true;

            setTimeout(() => {
              this.succesAlert = false;
            }, 10000)

            this.getUsers()
          }
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
          } else if (error.response.status == 500) {
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
          }
        });
    },
    processEditUser: function() {
      this.succesAlert = false;
       // Check rol type
      if (this.editedUser.role == "Ontwikkelaar"){
        this.editedUser.role = 2;
      } else if (this.editedUser.role == "Administrator") {
        this.editedUser.role = 1;
      } else {
        this.editedUser.role = 0;
      }
      // Post values to api
      axios
        .post(this.$API_LINK+"/update/user", this.editedUser)
        .then(res => {
          if (res.status == 200) {
            if (this.editedUser.role == 2){
              this.editedUser.role = "Ontwikkelaar";
            } else if (this.editedUser.role == 1) {
              this.editedUser.role = "Administrator";
            } else {
              this.editedUser.role = "Gebruiker";
            }
            // Set editDialog to invisible
            this.editDialog = false;
            this.succesAlert = true;

            setTimeout(() => {
              this.succesAlert = false;
            }, 10000)

            this.getUsers()
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            // Handle 401
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
          }
        });

      // Set editDialog to invisible
      this.editDialog = false;
    },
    insertUser: function() {
      // Set insertDialog to visible
      this.insertDialog = true;
    },
    editUser: function(user) {
      // Set selected user data to the editDialog form
      this.editedUser = user.item;

      // Set editDialog to visible
      this.editDialog = true;
    },
    deleteItem: function(user) {
      if (confirm("Weet u zeker dat u deze gebruiker wilt verwijderen?")) {
        this.succesAlert = false;
        const params = {
          id: user.item.id
        };

        // Perform POST request to delete user
        axios
          .post(this.$API_LINK+"/delete/user", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.succesAlert = true;
              setTimeout(() => {
                this.succesAlert = false;
              }, 10000)
              this.getUsers();
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.unknownErrorAlert = false
              },10000)
            }
          });
      }
    },
    disableUser: function(user){
      const action = user.item.active ? "uitschakelen" : "inschakelen"
      const confirmMessage = `Weet u zeker dat u deze gebruiker wilt ${action}?`
      if(confirm(confirmMessage)){
        const params = {
          id: user.item.id,
        };
        // Perform POST request to disable user
        axios
          .post(this.$API_LINK+"/disable/user", params)
          .then(res => {
            if (res.status == 200) {
              // update items from table
              this.getUsers();
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.unknownErrorAlert = false
              },10000)
            }
          });
      }
    }, 
    getUsers: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Execute POST request to retrieve users for user matching authToken
      axios
        .post(this.$API_LINK+"/retrieve/user/all", params)
        .then(res => {
          if (res.status == 200) {
            // Clean users table first
            this.users = [];

            // If the retrieval was successful
            // Set users variable to result data
            this.tempUsers = res.data;
            res.data.forEach(user => {
              if (user.role == 2){
                user.role = "Ontwikkelaar";
                this.users.push(user);
              } else if (user.role == 1){
                user.role = "Administrator";
                this.users.push(user);
              } else {
                user.role = "Gebruiker";
                this.users.push(user);
              }
            });
          }
        })
        .catch(error => {
          if (error.status == 403) {
            // Handle 403
            this.notAdminAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.notAdminAlert = false
            },10000)
          }
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.notFoundAlert = false
            },10000)
          }
        });
    },
    validate: function() {
      if (this.$refs.insertForm.validate()) {
        this.processInsertUser();
      }
    }
  }
};
</script>
