<template>
    <div id="overview">
      <v-container fluid>
        <v-layout align-center justify-center text-xs-center>
          <div text-xs-center style="width:100%">
            <v-alert :value="serviceImportedAlert" type="success" transition="fade-transition" dismissible>Service is succesvol geïmporteerd.</v-alert>
            <v-alert v-model="serviceEdited" type="success" transition="fade-transition" dismissible>Service is succesvol bijgewerkt.</v-alert>
  
            <v-layout align-end justify-end>
              <v-tooltip bottom>
                <span>Service toevoegen</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" @click="createService()" v-if="$session.get('role') == 1" class="mb-2" justify-right style="margin-right:10px;"><font-awesome-icon icon="plus" size="lg"/></v-btn>
                </template>
              </v-tooltip>
            </v-layout>
    
            <v-dialog v-model="editDialog" width="50%">
            <v-card>
              <v-card-title class="headline lighten-2" primary-title>Service wijzigen</v-card-title>
              <v-card-text>
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-row v-if="editDialog"
                      style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field v-model="editedService.repoName" :rules="serviceNameRules" outlined label="Name">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="processEditService()">Bijwerken</v-btn>
                <v-btn color="primary" text style="margin-left: 10px;" @click="editDialog = false">Sluiten</v-btn>
              </v-card-actions>
              </v-card>
            </v-dialog>
  
            <!-- Create service -->
            <v-dialog v-model="createDialog" width="50%">
              <v-form ref="createForm" v-model="valid">
                <v-card>
                  <v-card-title>Service aanmaken</v-card-title>
                  <v-card-text>
                    <v-row style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                      <v-col cols="12" sm="6" md="8" class="paramcol">
                        <v-text-field v-model="createdService.repoName" required :rules="serviceNameRules" outlined label="Service name" ></v-text-field>
                      </v-col>
                    </v-row>
                   </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="validateCreateService()">Opslaan</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
  
            <v-card>
              <v-card-title>Services<v-spacer></v-spacer><v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" single-line hide-details></v-text-field></v-card-title>
              <v-data-table :headers="headers" :items="services" :search="search" sort-by="repoName" multi-sort :single-expand="singleExpand" :expanded.sync="expanded" show-expand class="elevation-1">
                <template v-slot:[`item.action`]="service">
                  <v-icon v-if="$session.get('role') == 1" small @click="deleteService(service)">delete</v-icon>
                  <v-icon v-if="$session.get('role') == 1" v-bind="attrs" v-on="on" small @click="editService(service)">edit</v-icon>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td v-if="item" :colspan="headers.length">
                    <v-row style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                        <v-col cols="4" sm="12" md="6">
                            <v-text-field v-model="item.repoName" label="Name" readonly disabled></v-text-field>
                        </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
    
  export default {
    name: "Overview",
    data() {
      return {
        importDialog: false,
        createDialog: false,
        showDialog: false,
        editedService: {
          id: 0,
          repoName: '',
        //   sticker_code: '',
        },
        createdService: {
          id: 0,
          repoName: '',
        //   sticker_code: '',
        },
        serviceEdited: false,
        serviceImportedAlert: false,
        editedAppLabel: "",
        shownTemplateString: "",
        expanded: [],
        singleExpand: false,
        search: '',
        drawer: null,
        unknownErrorAlert: false,
        notFoundAlert: false,
        admin: false,
        services: [],
        modifyService: {
          repoName: '',
          appLabel: '',
          content: null
        },
        editDialog: false,
        item: null,
        headers: [
          { text: "ID", align: "left", value: "id" },
          { text: "Naam", align: "left", value: "repoName" },
          { text: "Acties", value: "action", sortable: false }
        ],
        serviceNameRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(String(v).includes(" ")){return `Er mogen geen spaties in de naam zitten`}
            return true
          }
        ],
      };
    },
    beforeCreate: function() {
      if (!this.$session.exists() || this.$session.get("authToken") == "") {
        // If there is no valid authentication session, reroute.
        this.$router.push("/");
      }
    },
    mounted: function() {
      // Get services on initial page load
      this.$nextTick(this.getServices());
  
      if (this.$session.get("role") != 1) {
        // Remove userid & actions columns if user isn't admin
        this.headers.splice(-2, 1);
      }
      },
    methods: {
      getServices: function() {
        // Execute GET request to retrieve services for user matching authToken
        axios
          .get(this.$API_LINK+"/retrieve/servicetfl")
          .then(res => {
            if (res.status == 200) {
              // If the retrieval was successful
              // Set services variable to result data  
              this.services = res.data
              console.log("res", res)
              
              console.log("services", this.services)
            }
          })
          .catch(error => {
            if (error.status == 404) {
              // Handle 404
              this.notFoundAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.notFoundAlert = false
              },10000)
            } else {
              console.log(error)
            }
          });
      },
      deleteService: function(service) {
        if (confirm("Weet u zeker dat u deze service wilt verwijderen?")) {
          const params = {
            id: service.item.id
          };
  
          // Perform DELETE request to delete user
          axios
            .post(this.$API_LINK+"/delete/servicetfl", params)
            .then(res => {
              if (res.status == 200) {
                // Remove item from table
                this.getServices()
              }
            })
            .catch(error => {
              if (error.status == 500) {
                // Handle 500
                this.unknownErrorAlert = true;
                // Automatically close alert after 10s
                setTimeout(()=>{
                  this.unknownErrorAlert = false
                },10000)
              }
            });
        }
      },
      importService: function() {
        this.importDialog = true
      },
      editService: function(service) {
        this.serviceEdited = false
        console.log(service)
        this.editedService = service.item
        this.editDialog = true;
        console.log(this.editedService)
      },
      processEditService: function() {
  
        let newService = {
          id: this.editedService.id,
          repoName: this.editedService.repoName,
        }
  
        // Perform POST request to insert userpage
        axios
          .post(this.$API_LINK+"/update/servicetfl/" + this.editedService.id, newService)
          .then(res => {
            if (res.status == 200) {
              // Destroy session and reroute to main page
              this.serviceEdited = true
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.serviceEdited = false
              },10000)
  
              this.editDialog = false
              this.getServices()
            }
          })
          .catch(error => {
            if (error.response.status == 500) {
              // Handle 404
              this.userpageEditFailedAlert = true
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.userpageEditFailedAlert = false
              },10000)
            }
          });
      },
      createService: function() {
        this.createDialog = true
      },
      validateCreateService: function () {
        if (this.$refs.createForm.validate()) {
          this.invalidFields = ""
          this.processCreateService()
        } else {
          let error = this.$refs.createForm.inputs.filter(field => field.valid === false);
          this.invalidFields = "De volgende velden zijn incorrect: ";
          error.forEach(err => {
            this.invalidFields += err.$refs.label.innerHTML + ", ";
          })
          this.invalidFields = this.invalidFields.slice(0, this.invalidFields.length-2)
        }
      },     
      processCreateService: function() {
        axios
          .post(this.$API_LINK+"/store/servicetfl", this.createdService)
          .then(res => {
            console.log("status: ", status)
            if (res.status == 200) {
              // Destroy session and reroute to main page
              this.serviceEdited = true
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.serviceEdited = false
              },10000)
  
              this.createDialog = false
              this.getServices()
            }
          })
          .catch(error => {
            console.log("error: ", error)
            if (error.response.status == 500) {
              // Handle 404
              this.serviceImportedAlert = true
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.serviceImportedAlert = false
              },10000)
            }
          });
       
      },
    },
    
  };
  </script>
  <style>
    .paramtextfield v-text-field {
      padding: 0 !important;
    }
  
    .paramtextfield input {
      padding: 0 !important;
    }
  
    .paramcol {
      padding: 0px 16px;
    }
  </style>