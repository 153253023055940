<template>
  <div id="overview">
    <v-container fluid>
      <v-row align="stretch">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="6">
          <v-card style="height: 100%">
            <v-card-title>Teneo Configuration Tool</v-card-title>
            <v-card-subtitle>Gegenereerde userpages deze maand</v-card-subtitle>
            <v-card-text>Tot nu toe zijn er deze maand <b>{{ countUserpages() }}</b> userpages gemaakt.</v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <v-simple-table height="230px" fixed-header dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Dag</th>
                      <th class="text-left">Userpages</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in labels" :key="index">
                      <td>{{ item }}</td>
                      <td>{{ value[item-1] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="6">
          <v-card style="height: 100%">
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .02)">
                <v-sparkline :labels="labels" :value="value" :gradient="gradient" :smooth="radius || false" :padding="padding" :line-width="width" :auto-draw-duration="drawduration" 
                :stroke-linecap="lineCap" :gradient-direction="gradientDirection" :fill="fill" :type="type" :label-size="labelsize" :auto-line-width="autoLineWidth" auto-draw >
                  <template v-slot:label="item">{{ item.value }}</template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">Gemaakte userpages per dag</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <v-btn block text to="/userpages">Ga naar userpages</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
  ['#3343FF', '#33A9FF', '#33FFEF'],
  ['#f2fcff', '#bfefff', '#80dfff', '#40cfff', '#00bfff']
]

export default {
  name: "Overview",
  data() {
    return {
      width: 2,
      radius: 4,
      padding: 4,
      lineCap: 'round',
      gradient: gradients[6],
      labels: [],
      value: [],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,
      drawduration: 2000,
      labelsize: 5,
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    this.$nextTick(this.setLabels())
    this.$nextTick(this.setValues())
  },
  methods: {
    countUserpages: function() {
      let count = 0
      this.value.forEach(element => {
        count += element
      }); 

      return count
    },
    setValues: function() {
      axios.get(this.$API_LINK+"/retrieve/userpages/currentmonth")
      .then(res => {
        if (res.status == 200) {
          let results = res.data
          results = results.slice(0, this.labels.length)
          this.value = results
        }
      })
    },
    setLabels: function() {
      let labelArray = []

      for (let i = 0; i < this.daysInCurrentMonth(); i++) {
        labelArray.push(i + 1)
      }

      this.labels = labelArray
    },
    daysInCurrentMonth: function() {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
    },
  }
};
</script>
