<template>
  <div id="overview">
    <v-container fluid>
      <v-card>
        <v-card-title>Mijn account</v-card-title>
        <v-card-text>
          <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition" dismissible>
            Er deed zich een onverwachte fout voor. Probeer het later opnieuw.
          </v-alert>
          <v-alert :value="updatedAlert" type="success" transition="fade-transition" dismissible>
            Accountsgegevens zijn bijgewerkt.
          </v-alert>
          <v-alert :value="networkErrorAlert" type="warning" transition="fade-transition" dismissible>
            Er deed zich een netwerkfout voor. Probeer het later opnieuw.
          </v-alert>
          <v-form ref="accountForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="editedUser.username" :rules="euUsernameRules" label="Gebruikersnaam" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="editedUser.email" :rules="euEmailRules" label="Emailadres" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="editedUser.password" :rules="euPasswordRules" error-count="2" label="Nieuw wachtwoord" type="password"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="passwordRepeatInput" :rules="passwordRepeatRules" error-count="2" label="Wachtwoord herhaling" type="password"></v-text-field>
              </v-col>    
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-btn :disabled="!valid" color="primary" @click="validate">Bijwerken</v-btn>
                <p style="margin-top: 10px; margin-bottom: 0px;" class="caption">Wilt u uw account verwijderen? Klik dan <a @click="toDeleteUser()">hier</a>.</p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Account",
  data() {
    return {
      passwordRepeatInput: null,
      valid: false,
      unknownErrorAlert: false,
      networkErrorAlert: false,
      updatedAlert: false,
      editedUser: {
        id: 0,
        username: null,
        company: null,
        email: null,
        password: null,
        role: 0
      },
      euUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      euEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value) || "Emailadres is ongeldig"
      ],
      euPasswordRules: [
        // value => (value && value.length >= 5) || "Wachtwoord moet uit ten minste 5 tekens bestaan",
      ],
      passwordRepeatRules: [
        value => (value == this.editedUser.password) || "De ingevulde wachtwoorden moeten overeen komen"
      ]
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get devices on initial page load
    this.$nextTick(this.getUser());
  },
  methods: {
    getUser: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      }

      // Perform POST request to get user data
      axios.post(this.$API_LINK+"/retrieve/user", params)
      .then(res => {
        if (res.status == 200) {
          // Disable network error
          this.networkErrorAlert = false;
          // Do something

          this.editedUser = res.data
        }
      })
      .catch(error => {
        if (!error.response) {
          // Network error
          this.networkErrorAlert = true;
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.networkErrorAlert = false
            },10000)
        }
      })
    },
    updateUser: function() {
      // Perform POST request to update user
      axios.post(this.$API_LINK+"/update/user", this.editedUser)
      .then(res => {
        if (res.status == 200) {
          // Disable network error
          this.networkErrorAlert = false;
          // Show success message
          this.unknownErrorAlert = false;
          this.updatedAlert = true;
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.updatedAlert = false
            },10000)
        }
      })
      .catch(error => {
        if (!error.response) {
          // Network error
          this.networkErrorAlert = true;
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.networkErrorAlert = false
            },10000)
        } else if (error.response.status == 401) {
          // Handle 401
          this.updatedAlert = false;
          this.unknownErrorAlert = true;
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
        }
      })
    },
    validate: function() {
      if (this.$refs.accountForm.validate()) {
        this.updateUser();
      }
    }
  }
};
</script>