import Vue from "vue";
import VueRouter from "vue-router";
import LoginComponent from "../components/login.vue";
import OverviewComponent from "../components/overview.vue";
import UserpagesComponent from "../components/userpages.vue";
import UserpagesTFLComponent from "../components/userpagesTFL.vue";
import ServicesComponent from "../components/services.vue";
import MacadressesComponent from "../components/macadresses.vue";
import ServicestflComponent from "../components/servicesTFL.vue";
import AccountComponent from "../components/account.vue";
import UsersComponent from "../components/users.vue";
import DeleteUserComponent from "../components/deleteuser.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: {
            name: "login",
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginComponent
    },
    {
        path: "/overview",
        name: "overview",
        component: OverviewComponent
    },
    {
        path: "/userpages",
        name: "userpages",
        component: UserpagesComponent
    },
    {
        path: "/userpagesTFL",
        name: "userpagesTFL",
        component: UserpagesTFLComponent
    },
    {
        path: "/services",
        name: "services",
        component: ServicesComponent
    },
    {
        path: "/macadresses",
        name: "macadresses",
        component: MacadressesComponent
    },
    {
        path: "/servicesTFL",
        name: "servicesTFL",
        component: ServicestflComponent
    },
    {
        path: "/account",
        name: "account",
        component: AccountComponent
    },
    {
        path: "/users",
        name: "users",
        component: UsersComponent
    },
    {
        path: "/delete/user",
        name: "deleteuser",
        component: DeleteUserComponent
      },
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;