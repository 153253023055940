const HTML_TEMPLATE = `<!DOCTYPE html>
<html>
  <head>
    <style>
    html,body{
    height:297mm;
    width:210mm;
    margin-top: 0;
    box-sizing: content-box;
    }
    table {
        height: 100%;
        width: 100%;
    }
    tr {
        height:10%;
    }
    td {
        width:25%
    }
    .card {
        margin-top: 6mm;
    }
    .eui {
        width: 100%;
        float:left;
    }
    .firstspan {
        margin-top: auto;
    }
    .productcode {
        font-family: Calibri;
        font-size: 10pt;
        font-weight: bold;
    }
    .deveui {
        font-family: Calibri;
        font-size: 12pt;
        
    }
    .version {
        font-family: Calibri;
        font-size: 6pt;
        font-weight: bold;
    }
    .sites {
        font-family: Calibri;
        font-size: 7pt;
        font-weight: bold;
    }
    </style>
  </head>
  <body>
        <table>
        </table>
  </body>
</html>`
exports.HTML_TEMPLATE = HTML_TEMPLATE