<template>
  <v-app>
    <v-navigation-drawer v-if="this.$router.history.current.path != '/login'" v-model="drawer" app>
      <v-list dense>
        <v-list-item to="/overview">
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>description</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Userpages</v-list-item-title>
            </v-list-item-content>
          </template>

        <v-list-item to="/userpages"> 
          <v-list-item-content>
            <v-list-item-title style="padding:0px 0px 0px 60px;">Userpages BMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/userpagesTFL"> 
          <v-list-item-content>
            <v-list-item-title style="padding:0px 0px 0px 60px;">Userpages TFL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
        <v-list-item to="/services">
          <v-list-item-action>
            <v-icon>build</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('role') != 0" to="/macadresses">
          <v-list-item-action>
            <v-icon>dns</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Application EUIs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('role') == 1" to="/servicesTFL">
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Services TFL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/account">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mijn account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('role') != 0" to="/users">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gebruikers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="toggleDarkMode">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer app>
      <span>&copy; Teneo IoT B.V. 2024 - versie {{ this.$VERSION }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
    };
  },
  mounted: function() {
    if (!this.$session.exists()) {
      this.$router.replace({ name: "login" });
    }

    // Color theme
    const theme = localStorage.darkTheme
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    }
  },
  methods: {
    toggleDarkMode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.darkTheme = this.$vuetify.theme.dark.toString()
    },
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios
        .post(this.$API_LINK+"/logout", params)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.$session.destroy();
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
  }
};
</script>
