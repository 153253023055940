[<template>
    <div id="overview">
      <v-container fluid>
        <v-layout align-center justify-center text-xs-center>
          <div text-xs-center style="width:100%">
            <v-alert v-model="userpageInsertFailedAlert" type="error" transition="fade-transition" dismissible>Generen van
              gemaakte userpage is mislukt. Controleer de ingevulde waarden of server verbinding.</v-alert>
            <v-alert v-model="selectServiceFailedAlert" type="error" transition="fade-transition" dismissible>Het selecteren van een service
              is mislukt. Controleer de geselecteerde waarde</v-alert>
            <v-alert v-model="userpageEditFailedAlert" type="error" transition="fade-transition" dismissible>Bijwerken van
              userpage is mislukt. Controleer de ingevulde waarden.</v-alert>
            <v-alert v-model="userpageInsertedAlert" type="success" transition="fade-transition" dismissible>Userpage is
              succesvol gegenereerd.</v-alert>
            <v-alert v-model="userpageEditedAlert" type="success" transition="fade-transition" dismissible>Userpage is
              succesvol bijgewerkt.</v-alert>
            <v-alert v-model="sliderIncorrectAlert" type="warning" transition="fade-transition" dismissible>Let op:
              Sliders zijn mogelijk niet correct ingesteld.</v-alert>
            <v-alert v-model="userpagesInsertFailedAlert" type="error" transition="fade-transition" dismissible>Genereren
              van userpages mislukt. Controleer de ingevulde waarden.</v-alert>
            <v-alert v-model="userpagesInsertedAlert" type="success" transition="fade-transition" dismissible>Userpages
              zijn succesvol gegenereerd.</v-alert>
            <v-snackbar v-model="copySnackbar" :timeout="2000"> Gekopieerd </v-snackbar>
            
            <v-layout style="position:fixed; z-index:5; right:0; padding-top:9px; right:10px;" align-end justify-end>
              <v-tooltip bottom>
                <span>Userpage toevoegen</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" @click="insertUserpage()" dark class="mb-2"
                    justify-right style="margin-right:10px;">
                    <font-awesome-icon icon="plus" size="lg" />
                  </v-btn>
                </template>
              </v-tooltip>
              <v-tooltip bottom>
                <span>Meerdere userpages toevoegen</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" @click="insertUserpages()" dark class="mb-2"
                    justify-right style="margin-right:10px;">
                    <font-awesome-icon icon="folder-plus" size="lg" />
                  </v-btn> <!-- folder-plus-->
                </template>
              </v-tooltip>
              <v-tooltip bottom>
                <span>Bulk wijzigen</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary" @click="editUserpages()" dark class="mb-2" justify-right
                    style="margin-right:10px;">
                    <font-awesome-icon icon="edit" size="lg" />
                  </v-btn> <!-- folder-plus-->
                </template>
              </v-tooltip>
              <v-tooltip bottom>
                <span>Bulk verwijderen</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="$session.get('role') == 1" :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary" @click="deleteUserpages()" dark class="mb-2"
                    justify-right style="margin-right:10px;">
                    <font-awesome-icon icon="trash" size="lg" />
                  </v-btn> <!-- folder-plus-->
                </template>
              </v-tooltip>
              <v-tooltip bottom>
                <span>Print stickers</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary" @click="printDialog = true" dark class="mb-2"
                    justify-right style="margin-right:10px;">
                    <font-awesome-icon icon="print" size="lg" />
                  </v-btn> <!-- folder-plus-->
                </template>
              </v-tooltip>
              <v-tooltip  bottom>
                <span>Bulk bin download</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary" @click="downloadUserpages()" dark class="mb-2"
                    justify-right style="margin-right:10px;">
                    <v-icon medium>mdi-cloud-download</v-icon>
                  </v-btn> <!-- folder-plus-->
                </template>
              </v-tooltip>
            </v-layout>
  
  
            <v-card >
              <v-card-title style="padding-top:32px">Userpages TFL
                <v-tooltip v-model="show" bottom>
                  <span>Refresh userpages</span>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="refreshUserpages()" class="ma-2"
                      justify-right style="margin-left:10px;" icon>
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
              </v-tooltip>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken"
                  @change="selectedUserpages = []" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table v-model="selectedUserpages" ref="table" :headers="headers" :items="userpages" :search="search" :custom-filter="customFilter"
                :footer-props="{
                  'items-per-page-options': [10, 20, 50, 100, -1]
                }" :loading="loadUserpages" sort-by="id" :single-expand="singleExpand" :expanded.sync="expanded" show-select show-expand
                multi-sort class="elevation-1">
  
                <template v-slot:[`item.action`]="userpage">
                  <v-icon small class="mr-2" @click="editUserpage(userpage)">edit</v-icon>
                  <v-icon v-if="$session.get('role') == 1" class="mr-2" small @click="deleteUserpage(userpage)">delete</v-icon>
                  <v-icon class="mr-2" small @click="downloadUserpage(userpage)">mdi-cloud-download</v-icon>
    
                </template>
  
                <template v-slot:[`item.status_name`]="{ item }">
                  <v-chip v-on="on" :color="item.status_color">
                    {{ item.status_name }}
                  </v-chip>
                </template>
  
                <!-- Overview userpages -->
                <template v-slot:expanded-item="{ headers, item }">
  
                  <td
                    v-if="item && item.config"
                    :colspan="headers.length">
                    <v-expansion-panels>
                      <v-expansion-panel>
                      <v-row style="padding:20px 16px 0px 20px;">
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="item.name" outlined label="Name" :rules="stringRules" disabled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="item.softwareVersion" :clearable="false" 
                            item-text="name" outlined label="Software Version" disabled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="item.hardwareVersion" :clearable="false" 
                            item-text="name" outlined label="Hardware Version" disabled>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel>
                      <v-expansion-panel accordion v-for="(items, index) in item.config" :key="index">
                        <v-expansion-panel-header style="padding:0px 20px 0px 20px;" color="grey-lighten-1">{{items.name}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="padding-top: 20px">
                          <v-row v-for="(property, parIndex) in items.properties"
                            :key="parIndex">
                            <v-col cols="12" sm="6" md="4" class="paramcol">
                              <v-text-field v-if="property.data_type === 'ArrayU8' && property.array_length === 16"
                                v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv6Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'ArrayU8' && property.array_length === 4"
                                v-mask="'NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv4Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U8'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU8Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU16Rules" disabled></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'U32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU32Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU64Rules" disabled></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I8'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI8Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI16Rules" disabled></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI32Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI64Rules" disabled></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'F32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF32Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'F64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF64Rules" disabled></v-text-field>
                              <v-checkbox v-else-if="property.data_type === 'Bool'" v-model="property.value" class="paramtextfield"
                                outlined :label="property.name" dense disabled></v-checkbox>
                              <v-select v-else-if="property.data_type === 'Enumeration'" :rules="selectRules"
                                v-model="property.value" :items="getEnumItems(property)" item-text="name"                                  outlined :label="property.enum_name" disabled></v-select>
                              <v-text-field v-else class="paramtextfield" v-model="property.value"
                                @wheel="$event.target.blur()" type="number" outlined :label="property.name" dense disabled>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-layout>
      </v-container>
  
      <v-dialog v-model="printDialog" width="70%">
        <v-form ref="printForm">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>{{ selectedUserpages.length }} Stickers printen
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="printedVersion" outlined label="Print versie"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="printedIndex" type="number" outlined label="Start index"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="printSticker()">Printen</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
  
      <!-- Insert multiple userpages -->
      <v-dialog v-model="csvDialog" width="70%">
        <v-form ref="insertMultipleForm" v-model="valid">
          <v-stepper v-model="insertUserpageStepper">
            <v-stepper-header>
              <v-stepper-step :complete="insertUserpageStepper > 1" step="1">Service</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="insertUserpageStepper > 2" step="2">Versie</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="insertUserpageStepper > 3" step="3">hardware</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="insertUserpageStepper > 4" step="4">Aantal</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">Userpage</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="toStep2()" v-model="serviceName" outlined label="Service name"
                          :items="services.map(m => m.repoName)" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="serviceName != null" @click="toStep2()">Volgende</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="toStep3()" v-model="selectedVersion" outlined label="Versie"
                          :items="versions.map(m => m.ref)" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="selectedVersion != null" @click="toStep3()">Volgende</v-btn>
                <v-btn @click="insertUserpageStepper = 1" text>Vorige</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="insertUserpageStepper = 4" v-model="selectedHardware" outlined label="Hardware"
                          :items="hardware" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="selectedHardware != null" @click="insertUserpageStepper = 4">Volgende</v-btn>
                <v-btn @click="insertUserpageStepper = 2" text>Vorige</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field @keyup.enter="toStep4()" v-model="userpageAmount" outlined
                          label="Aantal userpages" type="number" @wheel="$event.target.blur()" :rules="digitRules">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" @click="toStep4()">Volgende</v-btn>
                <v-btn @click="insertUserpageStepper = 3" text>Vorige</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-row style="padding:0px 16px;" >
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="insertedUserpages.name" required :rules="userpageNameRules" outlined label="name">
                              </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel>
                      <v-expansion-panel v-for="(items, index) in this.insertedUserpages.config" :key="index">
                        <v-expansion-panel-header style="padding:0px 16px;"> {{ items.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="padding-top: 20px">
                          <v-row v-for="(property, parIndex) in items.properties"
                            :key="parIndex">
                            <v-col cols="12" sm="6" md="4" class="paramcol">
                              <v-text-field v-if="property.name !== 'dev_eui' && property.data_type === 'ArrayU8' && property.array_length === 16"
                                v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv6Rules" ></v-text-field>
                                <v-text-field v-else-if="property.name === 'dev_eui' && property.data_type === 'ArrayU8' && property.array_length === 16"
                                v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv6Rules" disabled></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'ArrayU8' && property.array_length === 4"
                                v-mask="'NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv4Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U8'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU8Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU16Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'U32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU64Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I8'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI8Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI16Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI64Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'F32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'F64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF64Rules" ></v-text-field>
                              <v-checkbox v-else-if="property.data_type === 'Bool'" v-model="property.value" class="paramtextfield"
                                outlined :label="property.name" dense ></v-checkbox>
                              <v-select v-else-if="property.data_type === 'Enumeration'" :rules="selectRules"
                                v-model="property.value" :items="getEnumItems(property)" item-text="name" 
                                outlined :label="property.enum_name" ></v-select>
                              <v-text-field v-else class="paramtextfield" v-model="property.value"
                                @wheel="$event.target.blur()" type="number" outlined :label="property.name" dense >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" @click="validateInsertUserpages()">Genereer</v-btn>
                <v-btn @click="bulkStepper = 4" text>Vorige</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
                <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{invalidFields}}</p>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-dialog>
      
  
      <!-- Insert single userpage -->
      <v-dialog v-model="insertDialog" width="70%">
        <v-form ref="insertSingleForm" v-model="valid">
          <v-stepper v-model="insertUserpageStepper">
            <v-stepper-header>
              <v-stepper-step :complete="insertUserpageStepper > 1" step="1">Service</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="insertUserpageStepper > 2" step="2">Versie</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="insertUserpageStepper > 3" step="3">hardware</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">Userpage</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="toStep2()" v-model="serviceName" outlined label="Service name"
                          :items="services.map(m => m.repoName)" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="serviceName != null" @click="toStep2()">Volgende</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="toStep3()" v-model="selectedVersion" outlined label="Versie"
                          :items="versions.map(m => m.ref)" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="selectedVersion != null" @click="toStep3()">Volgende</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select @keyup.enter="toStep4()" v-model="selectedHardware" outlined label="Hardware"
                          :items="hardware" :rules="selectRules" required></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn color="primary" v-if="selectedHardware != null" @click="toStep4()">Volgende</v-btn>
                <v-btn color="primary" text @click="closeInsert()">Sluiten</v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-row style="padding:0px 16px;" >
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="insertedUserpage.name" required :rules="userpageNameRules" outlined label="name">
                              </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="insertedUserpage.iccid" :rules="iccidCodeRules" item-text="name" outlined label="ICCID" >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel>
                      <v-expansion-panel v-for="(items, index) in this.insertedUserpage.config" :key="index">
                        <v-expansion-panel-header style="padding:0px 16px;"> {{ items.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="padding-top: 20px">
                          <v-row v-for="(property, parIndex) in items.properties"
                            :key="parIndex">
                            <v-col cols="12" sm="6" md="4" class="paramcol">
                              <v-text-field v-if="property.data_type === 'ArrayU8' && property.array_length === 16"
                                v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv6Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'ArrayU8' && property.array_length === 4"
                                v-mask="'NN NN NN NN'" v-model="property.value"
                                class="paramtextfield" outlined :label="property.name" dense required
                                :rules="ipv4Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U8'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU8Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU16Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'U32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'U64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitU64Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I8'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI8Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I16'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI16Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'I32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'I64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitI64Rules" ></v-text-field>
                                <v-text-field v-else-if="property.data_type === 'F32'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF32Rules" ></v-text-field>
                              <v-text-field v-else-if="property.data_type === 'F64'" v-model.number="property.value"
                                class="paramtextfield" outlined :label="property.name"
                                @wheel="$event.target.blur()" type="number" dense :rules="digitF64Rules" ></v-text-field>
                              <v-checkbox v-else-if="property.data_type === 'Bool'" v-model="property.value" class="paramtextfield"
                                outlined :label="property.name" dense ></v-checkbox>
                              <v-select v-else-if="property.data_type === 'Enumeration'" :rules="selectRules"
                                v-model="property.value" :items="getEnumItems(property)" item-text="name"  
                                outlined :label="property.enum_name" ></v-select>
                              <v-text-field v-else class="paramtextfield" v-model="property.value"
                                @wheel="$event.target.blur()" type="number" outlined :label="property.name" dense >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
                <v-btn style="margin-left: 10px; margin-top:10px" color="primary" @click="validateInsertUserpage()">Toevoegen</v-btn> 
                <v-btn color="primary" text style="margin-left: 10px; margin-top:10px" @click="closeInsert()">Sluiten</v-btn>
                <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{invalidFields}}</p>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-dialog>
  


      <!-- Edit single userpage -->
      <v-dialog v-model="editDialog" width="70%">
        <v-form lazy-validation ref="editSingleForm" v-model="valid">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>Userpage wijzigen</v-card-title>
            <v-card-text>
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-row v-if="editDialog"
                    style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.name" :rules="userpageNameRules" outlined label="name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedUserpage.status_id" :clearable="false" :items="statusList" item-value="id"
                        item-text="name" outlined label="Status">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.softwareVersion" :clearable="false" 
                        item-text="name" outlined label="Software Version" disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.hardwareVersion" :clearable="false" 
                        item-text="name" outlined label="Hardware Version" disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.iccid" :rules="iccidCodeRules" item-text="name" outlined label="ICCID" >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel>
                <v-expansion-panel v-for="(items, index) in this.editedUserpage.config" :key="index">
                  <v-expansion-panel-header style="padding:0px 16px;"> {{ items.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="padding-top: 20px">
                    <v-row v-for="(property, parIndex) in items.properties"
                      :key="parIndex">
                      <v-col cols="12" sm="6" md="4" class="paramcol">
                        <v-text-field v-if="property.data_type === 'ArrayU8' && property.array_length === 16"
                          v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                          class="paramtextfield" outlined :label="property.name" dense required :rules="ipv6Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'ArrayU8' && property.array_length === 4"
                          v-mask="'NN NN NN NN'" v-model="property.value"
                          class="paramtextfield" outlined :label="property.name" dense required
                          :rules="ipv4Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U8'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU8Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U16'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU16Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'U32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU64Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'I8'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI8Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'I16'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI16Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'I32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'I64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI64Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'F32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitF32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'F64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitF64Rules" ></v-text-field>
                        <v-checkbox v-else-if="property.data_type === 'Bool'" v-model="property.value" class="paramtextfield"
                          outlined :label="property.name" dense ></v-checkbox>
                        <v-select v-else-if="property.data_type === 'Enumeration'" :rules="selectRules"
                          v-model="property.value" :items="getEnumItems(property)" item-text="name"  
                          outlined :label="property.enum_name" ></v-select>
                        <v-text-field v-else class="paramtextfield" v-model="property.value"
                          @wheel="$event.target.blur()" type="number" outlined :label="property.name" dense >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-divider></v-divider>
              <v-spacer></v-spacer>
              <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{invalidFields}}</p>
              <v-btn color="primary" style="margin-left: 10px; margin-top:10px" @click="validateEditUserpage()">Bijwerken</v-btn>
              <v-btn color="primary" text style="margin-left: 10px; margin-top:10px" @click="editDialog = false">Sluiten</v-btn>
            <v-spacer style="height:10px"></v-spacer>
          </v-card>
        </v-form>
      </v-dialog>
  


      <!-- Edit bulk userpage -->
      <v-dialog v-model="editBulkDialog" width="70%">
        <v-form ref="editBulkForm" v-model="validBulk">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>Userpage wijzigen</v-card-title>
            <v-card-text>
              <v-expansion-panels v-if="changeable" hide details accordion>
                <v-expansion-panel>
                  <v-row v-if="editBulkDialog"
                    style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editBulkName" :rules="userpageNameRules" outlined label="name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editBulkStatusId" :clearable="false" :items="bulkStatusList" item-value="id"
                        item-text="name" outlined label="Status">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.softwareVersion" :clearable="false" 
                        item-text="name" outlined label="Software Version" disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedUserpage.hardwareVersion" :clearable="false" 
                        item-text="name" outlined label="Hardware Version" disabled>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel>
                <v-expansion-panel v-for="(items, index) in this.editedUserpage.config" :key="index">
                  <v-expansion-panel-header style="padding:0px 16px;"> {{ items.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="padding-top: 20px">
                    <v-row v-for="(property, parIndex) in items.properties"
                      :key="parIndex">
                      <v-col cols="12" sm="6" md="4" class="paramcol">
                        <v-text-field v-if="property.data_type === 'ArrayU8' && property.array_length === 16"
                          v-mask="'NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN NN'" v-model="property.value"
                          class="paramtextfield" outlined :label="property.name" dense required :rules="ipv6Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'ArrayU8' && property.array_length === 4"
                          v-mask="'NN NN NN NN'" v-model="property.value"
                          class="paramtextfield" outlined :label="property.name" dense required
                          :rules="ipv4Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U8'" v-model="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU8Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U16'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU16Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'U32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'U64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitU64Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'I8'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI8Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'I16'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI16Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'I32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'I64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitI64Rules" ></v-text-field>
                          <v-text-field v-else-if="property.data_type === 'F32'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitF32Rules" ></v-text-field>
                        <v-text-field v-else-if="property.data_type === 'F64'" v-model.number="property.value"
                          class="paramtextfield" outlined :label="property.name"
                          @wheel="$event.target.blur()" type="number" dense :rules="digitF64Rules" ></v-text-field>
                        <v-checkbox v-else-if="property.data_type === 'Bool'" v-model="property.value" class="paramtextfield"
                          outlined :label="property.name" dense ></v-checkbox>
                        <v-select v-else-if="property.data_type === 'Enumeration'" :rules="selectRules"
                          v-model="property.value" :items="getEnumItems(property)" item-text="name"  
                          outlined :label="property.enum_name" ></v-select>
                        <v-text-field v-else class="paramtextfield" v-model="property.value"
                          @wheel="$event.target.blur()" type="number" outlined :label="property.name" dense >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card-text style="color:indianred; font-weight: bolder;" v-else> Er zijn verschillende data services geselecteerd, er kan dus niet worden bewerkt </v-card-text>
            </v-card-text>
            <v-divider></v-divider>
              <v-spacer></v-spacer>
              <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{invalidFields}}</p>
              <v-btn color="primary" style="margin-left: 10px; margin-top:10px" @click="processEditUserpages()">Bijwerken</v-btn>
              <v-btn color="primary" text style="margin-left: 10px; margin-top:10px" @click="editBulkDialog = false">Sluiten</v-btn>
            <v-spacer style="height:10px"></v-spacer>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import cryptoRandomString from "crypto-random-string"
  import JSZip from "jszip"
  
  export default {
    name: "Overview",
    data() {
      return {
        copySnackbar: false,
        changeable: false,
        printedVersion: "V1.00",
        invalidFields: "",
        printedIndex: 1,
        printDialog: false,
        valid: true,
        validBulk: true,
        csvDialog: false,
        editDialog: false,
        editBulkDialog: false,
        editBulkName: "",
        editBulkStatusId: -1,
        expanded: [],
        singleExpand: false,
        search: '',
        unknownErrorAlert: false,
        notFoundAlert: false,
        admin: false,
        userpages: [],
        selectedUserpages: [],
        services: [],
        versions: [],
        hardware: [],
        hardwareItems: [],
        selectedService: null,
        insertDialog: false,
        userpageInsertedAlert: false,
        userpageEditedAlert: false,
        userpageInsertFailedAlert: false,
        selectServiceFailedAlert: false,
        userpageEditFailedAlert: false,
        sliderIncorrectAlert: false,
        userpagesInsertedAlert: false,
        userpagesInsertFailedAlert: false,
        item: null,
        editedId: 0,
        bulkStepper: 1,
        insertUserpageStepper: 1,
        itemGroup: null,
        serviceName: null,
        selectedVersion: null,
        selectedHardware: null,
        loadInsertBulk: false,
        loadUserpages: false,
        stringRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            return true
          },
        ],
        selectRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            return true
          },
        ],
        devEuiRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^([0-9A-Fa-f]{16}|[0-9A-Fa-f]{32})$")){return `Deze EUI is niet geldig`}
            return true
          }
        ],
        digitRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            return true
          }          
        ],
        digitU8Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 0 || v > 255){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitU16Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 0 || v > 65535){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitU32Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 0 || v > 4294967295){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitU64Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 0 || v > 18446744073709551615){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitI8Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < -128 || v > 127){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitI16Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < -32768|| v > 32767){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitI32Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < -2147483648  || v > 2147483647){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        digitI64Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < -9223372036854775808 || v > 9223372036854775807){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        numberF32Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9].{0,1}[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 1.4e-45 || v > 3.4e38){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        numberF64Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^-?[0-9].{0,1}[0-9]*$")){return `Dit is geen geldig getal`}
            if(v < 5.0e-324 || v > 1.7e308){return `Dit getal valt buiten de toegestaande waarde`}
            return true
          }          
        ],
        ipv6Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^([0-9A-Fa-f]{32})$")){return `Deze EUI is niet geldig`}
            return true
          }
        ],
        ipv4Rules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(!String(v).replaceAll(" ", "").match("^([0-9A-Fa-f]{8})$")){return `Deze EUI is niet geldig`}
            return true
          }
        ],
        userpageNameRules: [
          v => {
            if(v == null) {return 'Veld mag niet leeg zijn'} 
            if(v.length == 0) {return 'Veld mag niet leeg zijn'}
            if(String(v).includes(" ")){return `Er mogen geen spaties in de naam zitten`}
            return true
          }
        ],
        iccidCodeRules: [
          v => {
            if(String(v).includes(" ")){return `Er mogen geen spaties in de naam zitten`}
            return true
          }
        ],
        headers: [
          { text: "ID", align: "center", value: "id" },
          { text: "Naam", align: "center", value: "name" },
          { text: "Status", filterable:false, align: "center", value: "status_name" },
          { text: "ICCID", align: "center", value: "iccid" },
          { text: "DevEUI", align: "center", value: "devEui" },
          { text: "Timestamp",  align: "center", value: "timestamp" },
          { text: "Acties", filterable:false, value: "action", sortable: false }
        ],
        //structure userpage_tfl
        insertedUserpage: {
          name: "",
          iccid: "",
          devEui: "00000000",
          softwareVersion: "",
          hardwareVersion: "",
          config: []
        },
        insertedUserpages: {
          name: "",
          iccid: "",
          devEui: "00000000",
          softwareVersion: "",
          hardwareVersion: "",
          group: "",
          config: []
        },
        editedUserpage: {
          name: "",
          iccid: "",
          devEui: "00000000",
          softwareVersion: "",
          hardwareVersion: "",
          config: []
        },
        json_fields: {
          'Nummer': 'number',
          'data_type': 'type',
          'DevEUI': 'dev_eui',
        },
        appeuidata: {
          searchValue: null,
          appEui: null,
          items: []
        },
        deveuidata: {
          items: []
        },
        dataTypeInt: {
          U8: "U8",
          U16: "U16",
          U32: "U32",
          U64: "U64",
          I8: "I8",
          I16: "I16",
          I32: "I32",
          I64: "I64",
        },
        dataTypeFloat: {
          F32: "F32",
          F64: "F64",
        },
      };
    },
    beforeCreate: function () {
      if (!this.$session.exists() || this.$session.get("authToken") == "") {
        // If there is no valid authentication session, reroute.
        this.$router.push("/");
      }
    },
    mounted: function () {
      // Get userpages on initial page load
      this.$nextTick(this.getUserpages());
      this.$nextTick(this.getServices());
      this.$nextTick(this.getStatus());
        
      if (this.$session.get("role") != 1) {
        // Remove userid & actions columns if user isn't admin
        this.headers.splice(-2, 1);
      }
    },
    methods: {
      async fetchData() {
        const params = {
          token: this.$session.get("authToken")
        };
  
        // Post to retrieve devices, return data of said devices when done
        const res = await axios.post(this.$API_LINK + "/retrieve/userpagestfl", params) // TODO: change this
        return res.data;
      },
      customFilter (value, search, item) {
          return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().replaceAll(' ', '').toLowerCase().includes(search.toLowerCase())
        },
      refreshUserpages: function(){
        this.getUserpages()
        this.getServices()
        this.getStatus()
      },
      
      getUserpages: function () {
        // Set loading userpages
        this.loadUserpages = true
        
         // Execute GET request to retrieve userpages for user matching authToken
        axios
          .get(this.$API_LINK + "/retrieve/userpagestfl")
          .then(res => {
            if (res.status == 200) {
              // If the retrieval was successful
              this.loadUserpages = false
              // Set userpages variable to result data
              
              this.userpages = this.setValueDecodeds(res.data)
              // this.userpages = res.data
              this.selectedUserpages = []
            }
          })
          .catch(error => {
            if (error.status == 404) {
              // Handle 404
              console.log(error)
              this.loadUserpages = false
              this.notFoundAlert = true;
              // Automatically close alert after 10s
              setTimeout(() => {
                this.notFoundAlert = false
              }, 10000)
            }
          });
      },
      getStatus: function () {
        // Execute GET request to retrieve userpages for user matching authToken
        axios
          .get(this.$API_LINK + "/retrieve/status/all")
          .then(res => {
            if (res.status == 200) {
  
              // Set userpages variable to result data
              this.statusList = res.data;
              this.bulkStatusList = structuredClone(this.statusList)
  
              this.bulkStatusList.unshift({
                id: -1,
                name: "",
                description: "",
                color: "#ffffff"
              })
            }
          })
          .catch(error => {
            if (error.status == 404) {
              // Handle 404
              this.notFoundAlert = true;
              // Automatically close alert after 10s
              setTimeout(() => {
                this.notFoundAlert = false;
              }, 10000)
            }
          });
      },
      setValueDecodeds: function (userpages) {
        // Set local userpages instance
        let newUserpages = userpages
        let _this = this
        // Loop through userpages
        newUserpages.forEach(function (userpage, userpageIndex) {
        // Ensure userpage.config is defined and is an array
          if (Array.isArray(userpage.config)) {
            // Loop through each configuration in userpage.config
            userpage.config.forEach(function (config, configIndex) {
              // Check if config.properties is defined and is an array
              if (Array.isArray(config.properties)) {

                // Loop through properties parameters for current config
                config.properties.forEach(function (parameter, parameterIndex) {
                  if (parameter.data_type === "ArrayU8" && parameter.array_length !== 0) {
                    newUserpages[userpageIndex].config[configIndex].properties[parameterIndex].value = _this.formatArray(parameter.value, parameter.array_length);
                  } 
                  else if (parameter.data_type in _this.dataTypeInt && parameter.value === undefined) {
                    newUserpages[userpageIndex].config[configIndex].properties[parameterIndex].value = 0;
                  } 
                  else if (parameter.data_type in _this.dataTypeFloat && parameter.value === undefined) {
                    newUserpages[userpageIndex].config[configIndex].properties[parameterIndex].value = 0.0;
                  } 
                  else if (parameter.data_type === "Bool" && parameter.value === undefined) {
                    newUserpages[userpageIndex].config[configIndex].properties[parameterIndex].value = false;
                  } 
                  else if (parameter.data_type === "Enumeration" && parameter.enum_values !== undefined) {
                    newUserpages[userpageIndex].config[configIndex].properties[parameterIndex].value = _this.setEnumNetwork(parameter);
                  }
                  if (parameter.name === "dev_eui") {
                    parameter.value = userpage.devEui;
                  }
                });
              }
            });
          }
        });
    
        // Return updated userpages
        return newUserpages
      },
      setValueDefaults: function (settings) {
        // Set local userpages instance
        let newSettings = settings
        let _this = this

        // Loop through userpages
        newSettings.forEach(function (setting, settingIndex) {
              // Check if config.properties is defined and is an array
          if (Array.isArray(setting.properties)) {
            // Loop through properties parameters for current config
            setting.properties.forEach(function (parameter, parameterIndex) {
              if (parameter.data_type === "ArrayU8" && parameter.array_length !== 0) {
                newSettings[settingIndex].properties[parameterIndex].value = _this.formatArray(parameter.value, parameter.array_length);
              } 
              else if (parameter.data_type in _this.dataTypeInt && parameter.value === undefined) {
                newSettings[settingIndex].properties[parameterIndex].value = 0;
              } 
              else if (parameter.data_type in _this.dataTypeFloat && parameter.value === undefined) {
                newSettings[settingIndex].properties[parameterIndex].value = 0.0;
              } 
              else if (parameter.data_type === "Bool" && parameter.value === undefined) {
                newSettings[settingIndex].properties[parameterIndex].value = false;
                  } 
              else if (parameter.data_type === "Enumeration" && parameter.enum_values !== undefined) {
                newSettings[settingIndex].properties[parameterIndex].value = _this.setEnumNetwork(parameter);
              }
            });
          }
        });
        // Return updated userpages
        return newSettings
      },
      setEnumNetwork: function (property) {
        if (property.value == undefined) {
          property.value = Object.keys(property.enum_values)[0]
        } 
        return property.value
      },
      getEnumItems: function(property){
        let tempdata = Object.keys(property.enum_values)
        return tempdata
      },
      formatArray: function(array, length) {
        if (!Array.isArray(array) || array == undefined) {
          array = new Array(length).fill(0)
        }
        let arrays = array.map(val => val.toString(16).padStart(2, '00')).join(' ');
        return arrays
      },
      reverseFormatArray: function (formattedString, length) {
        if (!formattedString) return new Array(length).fill(0);
        let format = formattedString.split(' ')
        let formatted = format.map(val => parseInt(val, 16));
        return formatted
      },
      getServices: function () {
        axios
          .get(this.$API_LINK+"/retrieve/servicetfl")
          .then(res => {
            if (res.status == 200) {
              // If the retrieval was successful
              // Set services variable to result data  
              this.services = res.data
            }
          })
          .catch(error => {
            if (error.status == 404) {
              // Handle 404
              this.notFoundAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.notFoundAlert = false
              },10000)
            } else {
              console.log(error)
            }
          });
      },
      insertUserpage: function () {
        //this.getNBsettings()
        this.invalidFields = "";
        this.insertDialog = true;
      },
      toStep2: function () {
        if (!this.serviceName){
          this.invalidFields = "Service is required";
          return
        }
        this.selectedService = this.serviceName
        axios
          .get(this.$API_LINK + "/retrieve/versions", { 
            params: {
              repoName: this.selectedService
            }
          })
          .then(res => {
            if (res.status == 200) {
              this.versions = res.data;
              this.insertUserpageStepper = 2
            }
          })
          .catch(error => {
            if (error.status == 500) {
              this.invalidFields = "Service cannot be found";
              return
            }
          });
      },
      toStep3: function () {
        if (!this.selectedVersion){
          this.invalidFields = "Version is required";
          return
        }
        this.selectedService = this.serviceName
        let version = this.selectedVersion.substring(this.selectedVersion.lastIndexOf('/')+ 1)
        axios
          .get(this.$API_LINK + "/retrieve/hardware", { 
            params: {
              repoName: this.selectedService,
              ref: version
            }
          })
          .then(res => {
            if (res.status == 200) {
              let hardwareVersion = res.data;
              // Update hardwareItems based on received data
              this.hardware = Object.keys(hardwareVersion.Features).map(key => ({
                text: key,
                value: key
              }))
              this.insertUserpageStepper = 3
            }
          })
          .catch(error => {
            if (error.status == 500) {
              console.error(error)
            }
          });
      },
      toStep4: function () {
        if (!this.selectedHardware){
          this.invalidFields = "Version is required";
          return
        }
        
        this.selectedService = this.serviceName
        let version = this.selectedVersion.substring(this.selectedVersion.lastIndexOf('/')+ 1)
        axios
          .get(this.$API_LINK + "/retrieve/config", { 
            params: {
              repoName: this.selectedService,
              ref: version
            }
          })
          .then(res => {
            if (res.status == 200) {
              if(this.insertUserpageStepper == 3){
                this.insertedUserpage.config = this.setValueDefaults(res.data.config);
                this.insertUserpageStepper = 4
              } else { 
                this.insertedUserpages.config = this.setValueDefaults(res.data.config);
                this.insertUserpageStepper = 5
              }
              
            }
          })
          .catch(error => {
            if (error.status == 500) {
              console.error(error)
            }
          });
      },
      closeInsert: function () {
        this.insertDialog = false
        this.csvDialog = false
        this.insertUserpageStepper = 1
      },
      
      validateInsertUserpage: function () {
        if (this.$refs.insertSingleForm.validate()) {
          this.invalidFields = ""
          this.processCreateUserpage()
        } else {
          let error = this.$refs.insertSingleForm.inputs.filter(field => field.valid === false);
          this.invalidFields = "De volgende velden zijn incorrect: ";
          error.forEach(err => {
            this.invalidFields += err.$refs.label.innerHTML + ", ";
          })
          this.invalidFields = this.invalidFields.slice(0, this.invalidFields.length-2)
        }
      },
      processCreateUserpage: function () {
        let _this = this
        // TODO: check if name is already active
        // TODO: check if devEui is already active
        for (let i = 0; i < this.insertedUserpage.config.length; i++) {
          let insertUserpage = this.insertedUserpage.config[i]
          if (insertUserpage.name == "NarrowbandSettings") {
            for (let j = 0; j < insertUserpage.properties.length; j++) {
              let currentParam = insertUserpage.properties[j]
              if (currentParam.name == "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                this.insertedUserpage.devEui = currentParam.value
                this.insertedUserpage.config[i].properties[j].value = this.reverseFormatArray(currentParam.value, 16)
              } 
              if (currentParam.name != "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                this.insertedUserpage.config[i].properties[j].value = this.reverseFormatArray(currentParam.value, currentParam.array_length)
              } 
            }
          }
        }
        this.insertedUserpage.softwareVersion = this.selectedVersion.substring(this.selectedVersion.lastIndexOf('/')+ 1)
        this.insertedUserpage.hardwareVersion = this.selectedHardware

        this.insertDialog = false
        // Perform POST request to insert userpage
        axios
          .post(this.$API_LINK + "/generate/userpagetfl", this.insertedUserpage)
          .then(res => {
            if (res.status == 200) {
              // Destroy session and reroute to main page
              this.userpageInsertedAlert = true
              // Automatically close alert after 10s
              setTimeout(() => {
                this.userpageInsertedAlert = false
              }, 10000)
              
              this.insertDialog = false
              this.insertUserpageStepper = 1
              this.getUserpages()
            }
          })
          .catch(error => {
            if (error.response.status == 404) {
              // Handle 404
              this.userpageInsertFailedAlert = true
              // Automatically close alert after 10s
              setTimeout(() => {
                this.userpageInsertFailedAlert = false
              }, 10000)
            }
          });
      },
      insertUserpages: function () {
        this.invalidFields = "";
        this.csvDialog = true;
      },
      validateInsertUserpages: function () {
        if (this.$refs.insertMultipleForm.validate()) {
          this.generateUserpages(this.userpageAmount)
        }
      },
      isToday: function (date) {
        const today = new Date();
  
        if (
          today.getFullYear() === date.getFullYear() &&
          today.getMonth() === date.getMonth() &&
          today.getDate() === date.getDate()
        ) {
          return true;
        }
  
        return false;
      },
      generateUserpages: function (upAmount) {
        let _this = this
        let userpages = []
        let groupName = Object.assign({}, _this.insertedUserpages).name;
        
        axios
          .get(this.$API_LINK + `/retrieve/grouptfl/${groupName}`, this.insertedUserpage)
          .then(res => {
            if (res.status == 200) {
  
              //Use the amount of existings userpages with this name to offset the counter in the userpage name.
              let _userpages = this.setValueDefaults(res.data)
  
              //Filter _userpages with todays date
              let groupLength = _userpages.length;
              let reverseFormattedValues = {};
              for (let index = 0; index < upAmount; index++) {
                // For each deveui, appeui, appkey, create userpage
                let tempAppKey = cryptoRandomString({ length: 32 })

                let userpage = Object.assign({}, _this.insertedUserpages);
  
                // General
                let correctAddress = tempAppKey.match(/.{1,2}/g);
        
                userpage.devEui = correctAddress.join(' ')

                if (this.userpages.devEui == userpage.devEui) {
                  tempAppKey = cryptoRandomString({ length: 32 })
                  correctAddress = tempAppKey.match(/.{1,2}/g);
                  userpage.devEui = correctAddress.join(' ')
                }
  
                //Removed spaces from the name for consistency
                userpage.name = userpage.name.replaceAll(" ", "")
  
                //The group name will be the base of the userpage name.
                userpage.group = userpage.name
  
                //The userpage name is the groupname with a index appended, to keep the names unique.
                //If any userpage are made on the same day with the same name, the index is kept and futher incremented.
                userpage.name = userpage.name + "_" + (index + 1 + groupLength)
                userpage.softwareVersion = this.selectedVersion.substring(this.selectedVersion.lastIndexOf('/')+ 1)
                userpage.hardwareVersion = this.selectedHardware
                
                
                // NarrowbandSettings >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                for (let i = 0; i < userpage.config.length; i++) {
                  let insertUserpage = userpage.config[i]
                  if (insertUserpage.name == "NarrowbandSettings") {
                    for (let j = 0; j < insertUserpage.properties.length; j++) {
                      let currentParam = insertUserpage.properties[j]
                      if (currentParam.name == "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                        userpage.config[i].properties[j].value = this.reverseFormatArray(userpage.devEui, 16)
                      } 
                      if (currentParam.name != "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                        if(index == 0){
                          reverseFormattedValues[j] = this.reverseFormatArray(currentParam.value, currentParam.array_length)
                        }
                        userpage.config[i].properties[j].value = reverseFormattedValues[j]
                      } 
                    }
                  }
                }
  
                //The userpage needs to be deepcopied otherwise references to previous userpages will be pushed.
                userpages.push(structuredClone(userpage))
              }
  
              this.loadInsertBulk = true
  
              const requestBody = { userpages: userpages}
              // Perform POST request to insert userpage 
              axios
                .post(this.$API_LINK + "/generate/userpagestfl", requestBody)
                .then(res => {
                  if (res.status == 200) {
                    // Destroy session and reroute to main page
                    this.loadInsertBulk = false
                    this.csvDialog = false
                    this.insertUserpageStepper = 1
                    this.getUserpages()
                    this.userpagesInsertedAlert = true
                    // Automatically close alert after 10s
                    setTimeout(() => {
                      this.userpagesInsertedAlert = false
                    }, 10000)
                  }
                })
                .catch(error => {
                  if (error.response.status == 404) {
                    // Handle 404
                    this.loadInsertBulk = false
                    this.userpagesInsertFailedAlert = true
                    // Automatically close alert after 10s
                    setTimeout(() => {
                      this.userpagesInsertFailedAlert = false
                    }, 10000)
                  }
                });
            }
          })
          .catch(error => {
            // Handle 404
            console.log(error)
            this.loadInsertBulk = false
            this.csvDialog = false
            this.userpageInsertFailedAlert = true
            this.insert
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageInsertFailedAlert = false
            }, 10000)
          });
      },
      editUserpage: function (userpage) {
        // Set selected userpage data to the editDialog form
        this.editedUserpage.config = userpage.item.config;
        this.editedUserpage.name = userpage.item.name
        this.editedUserpage.iccid = userpage.item.iccid
        this.editedUserpage.softwareVersion = userpage.item.softwareVersion
        this.editedUserpage.hardwareVersion = userpage.item.hardwareVersion
        this.editedUserpage.status_name = userpage.item.status_name;
        this.editedUserpage.status_id = userpage.item.status_id;
        this.editedUserpage.status_description = userpage.item.status_description;
        this.editedId = userpage.item.id
        // Set editDialog to visible
        this.invalidFields = "";
        this.editDialog = true;
      },
      validateEditUserpage: function () {
        if (this.$refs.editSingleForm.validate()) {
          this.invalidFields = ""
          this.processEditUserpage()
        } else {
          let error = this.$refs.editSingleForm.inputs.filter(field => field.valid === false);
          this.invalidFields = "De volgende velden zijn incorrect: ";
          error.forEach(err => {
            this.invalidFields += err.$refs.label.innerHTML + ", ";
          })
          this.invalidFields = this.invalidFields.slice(0, this.invalidFields.length-2)
        }
      },
      processEditUserpage: function () {
        let _this = this

        for (let i = 0; i < this.editedUserpage.config.length; i++) {
          let editUserpage = this.editedUserpage.config[i]
          if (editUserpage.name == "NarrowbandSettings") {
            for (let j = 0; j < editUserpage.properties.length; j++) {
              let currentParam = editUserpage.properties[j]
              if (currentParam.name == "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                this.editedUserpage.devEui = currentParam.value
                this.editedUserpage.config[i].properties[j].value = this.reverseFormatArray(currentParam.value, 16)
              } else if (currentParam.name != "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                this.editedUserpage.config[i].properties[j].value = this.reverseFormatArray(currentParam.value, currentParam.array_length)
              } 
            }

          }
        }
        
        this.editDialog = false
        // Perform POST request to insert userpage
        axios
          .post(this.$API_LINK + "/update/userpagetfl/" + this.editedId, this.editedUserpage)
          .then(res => {
            if (res.status == 200) {
              // Destroy session and reroute to main page
              this.userpageEditedAlert = true
              // Automatically close alert after 10s
              setTimeout(() => {
                this.userpageEditedAlert = false
              }, 10000)
  
              this.editDialog = false
              this.getUserpages()
            }
          })
          .catch(error => {
            if (error.response.status == 500) {
              // Handle 404
              this.userpageEditFailedAlert = true
              // Automatically close alert after 10s
              setTimeout(() => {
                this.userpageEditFailedAlert = false
              }, 10000)
            }
          });
      },
      editUserpages: function () {
        let changeableResult = true;
        let dataserviceName = this.selectedUserpages[0].config[0].name
        let softwareVersion = this.selectedUserpages[0].softwareVersion


        for(let i = 0; i < this.selectedUserpages.length; i++) {
          if(dataserviceName != this.selectedUserpages[i].config[0].name || softwareVersion != this.selectedUserpages[i].softwareVersion) {//Check whether the selections have the same includes (settings)
            changeableResult = false
            break;
          }
        }

        this.changeable = changeableResult;
  
        if(this.changeable) {
          this.editedUserpage = this.selectedUserpages[0]   
        }
    
        this.editBulkDialog = true;
        this.editBulkStatusId = -1
  
        let statusId = this.selectedUserpages[0].status_id
    
        //If the whole array consists of 1 status Id, change the select to this status id
        let filteredStatus = this.selectedUserpages.filter(up => { return up.status_id === statusId })
        if (filteredStatus.length === this.selectedUserpages.length) {
          this.editBulkStatusId = statusId
        }
      },
      resetBulkEdit: function () {
        this.editBulkName = ""
        this.editBulkStatusId = -1;
      },
      processEditUserpages: function () {
        let promises = [];
        let _this = this
        let reverseFormattedValues = {};

        this.selectedUserpages.forEach((up, index) => {
          let currentUserpage = up;

          if (this.changeable) {
            for (let i = 0; i < currentUserpage.config.length; i++) {
              let editUserpage = this.editedUserpage.config[i]

              if (editUserpage.name == "NarrowbandSettings") {
                for (let j = 0; j < editUserpage.properties.length; j++) {
                  let currentParam = editUserpage.properties[j]
                  
                  if (currentParam.name == "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                    currentUserpage.devEui = currentUserpage.devEui
                    currentUserpage.config[i].properties[j].value = this.reverseFormatArray(currentUserpage.config[i].properties[j].value, 16)
                  } 
                  if (currentParam.name != "dev_eui" && currentParam.data_type == "ArrayU8" && currentParam.array_length != undefined) {
                    if(index == 0){
                      reverseFormattedValues[j] = this.reverseFormatArray(currentParam.value, currentParam.array_length)
                    }
                    currentUserpage.config[i].properties[j].value = reverseFormattedValues[j]                  
                  } else {
                    currentUserpage.config[i].properties[j].value = this.editedUserpage.config[i].properties[j].value
                  }
                }
              } else {
                currentUserpage.config[i] = this.editedUserpage.config[i]
              }

            }
            
          }
  
          if (this.editBulkName !== "") {
            up.name = this.editBulkName + "_" + (index + 1);
            currentUserpage.Group = this.editBulkName;
          }
  
          if (this.editBulkStatusId != -1) {
            up.status_id = this.editBulkStatusId;
          }
    
          currentUserpage.status_name = up.status_name;
          currentUserpage.status_id = up.status_id;
          currentUserpage.status_description = up.status_description;
  
          this.editedId = up.id;
          promises.push(axios.post(this.$API_LINK + "/update/userpagetfl/" + this.editedId, currentUserpage));
  
        });
  
        Promise.all(promises)
          .then(results => {
            if (results.filter(res => { return res.status != 200 }).length === 0) {
              // Destroy session and reroute to main page
              this.userpageEditedAlert = true
              this.editBulkDialog = false;
              // Automatically close alert after 10s
              setTimeout(() => {
                this.userpageEditedAlert = false
              }, 10000)
  
              this.getUserpages()
              this.editBulkDialog = false;
            }
          })
          .catch(error => {
            console.log(error)
            this.userpageEditFailedAlert = true
            this.editBulkDialog = false;
            this.changeable = false
  
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageEditFailedAlert = false
            }, 10000)
          }
          );
    
        this.resetBulkEdit()
        this.editBulkDialog = false;
        this.changeable = false;
      },
      deleteUserpages: function () {
        if (confirm("Weet u zeker dat u " + this.selectedUserpages.length + " userpages wilt verwijderen?")) {
          let promises = [];
  
          this.selectedUserpages.forEach((up, index) => {
  
            const params = {
              id: up.id
            };
            promises.push(axios
              .post(this.$API_LINK + "/delete/userpagetfl", params)
            );
  
          });
  
          Promise.all(promises)
            .then(results => {
              if (results.filter(res => { return res.status != 200 }).length === 0) {
                this.getUserpages()
              }
            })
            .catch(error => {
              console.log(error)
            }
            );
        }
      },
      deleteUserpage: function (userpage) {
        if (confirm("Weet u zeker dat u deze userpage wilt verwijderen?")) {
          const params = {
            id: userpage.item.id
          };
  
          // Perform DELETE request to delete user
          axios
            .post(this.$API_LINK + "/delete/userpagetfl", params)
            .then(res => {
              if (res.status == 200) {
                // Remove item from table
                this.getUserpages()
              }
            })
            .catch(error => {
              if (error.status == 500) {
                // Handle 500
                this.unknownErrorAlert = true;
                // Automatically close alert after 10s
                setTimeout(() => {
                  this.unknownErrorAlert = false
                }, 10000)
              }
            });
        }
      },
      processGenerateBulk: function () {
        // Validate form data
        if (this.userpageAmount != 0) {
          const realDevLabel = this.insertedUserpages.name
          const realDevCount = this.userpageAmount
  
          const realItem = {
            devEuiCount: parseInt(realDevCount),
            devEuiLabel: realDevLabel
          }
  
          // POST request
          return axios.post(this.$API_LINK + "/generate/mac/bulktfl", realItem)
        }
      },
      downloadUserpage: function(userpage) {

        axios
          .get(this.$API_LINK + "/retrieve/userpagetfl/bin/"+userpage.item.name)
          .then(res => {
            if (res.status == 200) {
              // Handle result
              const up = res.data.find(up => {return up.name == userpage.item.name})
              if(up != null) {
                const hexToBytes = hex => {for (var bytes = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16)); return bytes; };
                const res = (hexToBytes(up.hexstring));
                let blob = new Blob([new Uint8Array(res)],{type:'application/octet-stream'})
                var element = document.createElement('a');
  
                element.setAttribute('href', URL.createObjectURL(blob));
                element.setAttribute('download', up.name+".bin");
  
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              }
            }
          })
          .catch(error => {
            console.log(error)
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(() => {
                this.unknownErrorAlert = false
              }, 10000)
            }
          });      
      },
      downloadUserpages : function()
      {
        if (this.selectedUserpages.length > 0) {
          var zip = new JSZip()
          let promises = [];
  
          this.selectedUserpages.forEach(userpage => {
            promises.push(axios
            .get(this.$API_LINK + "/retrieve/userpagetfl/bin/"+ userpage.name)
            .then(res => {
              if (res.status == 200) {
                // Handle result
                const up = res.data.find(up => {return up.name == userpage.name})
              
                if(up != null) {
                  const hexToBytes = hex => {for (var bytes = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16)); return bytes; };
                  const res = (hexToBytes(up.hexstring));
  
                  zip.file(up.name+".bin", res)
                }
              }
            })
            .catch(error => {
              if (error.status == 500) {
                // Handle 500
                this.unknownErrorAlert = true;
                // Automatically close alert after 10s
                setTimeout(() => {
                  this.unknownErrorAlert = false
                }, 10000)
              }
            }));
    
  
          })
  
          Promise.all(promises)
          .then(results => {
            zip.generateAsync({type:"blob"})
          .then(function(blob) {
              var element = document.createElement('a');
  
              element.setAttribute('href', URL.createObjectURL(blob));
              element.setAttribute('download', "binaries.zip");
  
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
          });
          })
  
          
        }
      },
      printSticker: function(){
        // let stickerCodes = this.selectedUserpages.flatMap((up) => {
        //   let service = this.services.find(s => s.name == up.config.DataService.name)
        //   if (service != null) {
        //     return [service.sticker_code, service.sticker_code];
        //   }
        //   return ["", ""];
        // })
        let data = {
            selectedUserpages: this.selectedUserpages.flatMap(up => [up.id, up.id]),
            // stickerCode: stickerCodes,
            deveui: this.selectedUserpages.flatMap(up => [up.devEui.replaceAll(" ", "").toUpperCase(), up.devEui.replaceAll(" ", "").toUpperCase()]),
            firmwareVersion: this.printedVersion,
            index: Math.min(Math.max(this.printedIndex-1, 0), 40)
          };
  
        axios.post(this.$API_LINK + "/print/stickertfl", data, { responseType: 'blob' })
        .then(response => {
        // Creëer een nieuwe Blob van de PDF data
        const blob = new Blob([response.data], { type: 'application/pdf' });
  
        // Creëer een URL voor de Blob
        const pdfUrl = window.URL.createObjectURL(blob);
  
        const newWindow = window.open(pdfUrl);
        // Controleer of het nieuwe venster succesvol is geopend
        if (newWindow) {
          // Wacht tot het nieuwe venster geladen is voordat je de print dialoog opent
          newWindow.onload = function() {
            try {
              newWindow.focus();
              newWindow.print();
            } catch (e) {
              console.error("Er is een fout opgetreden bij het openen van de print dialoog: ", e);
            }
          };
        } else {
          console.error('Het nieuwe venster kon niet worden geopend');
        }
      })
      .catch(error => {
        console.error("Er is een fout opgetreden: ", error);
      });
      this.printedVersion = "V1.00"
      this.printDialog = false
      this.printedIndex = 1
      },
    }
  };
  </script>
  
  <style>
  .paramtextfield v-text-field {
    padding: 0 !important;
  }
  
  .paramtextfield input {
    padding: 0 !important;
  }
  
  .paramcol {
    padding: 0px 16px;
  }
  </style>
  ]