<template>
  <div id="overview">
    <v-container fluid>
      <v-layout align-center justify-center text-xs-center>
        <div text-xs-center style="width:100%">
          <v-alert :value="serviceImportedAlert" type="success" transition="fade-transition" dismissible>Service is succesvol geïmporteerd.</v-alert>
          <v-alert :value="serviceImportFailedAlert" type="error" transition="fade-transition" dismissible>Importeren van service is mislukt. Controleer de ingevulde waarden.</v-alert>
          <v-alert :value="serviceJSONFailedAlert" type="error" transition="fade-transition" dismissible>Het JSON bestand bevat fouten, controleer en probeer opnieuw.</v-alert>
          <v-alert v-model="serviceEdited" type="success" transition="fade-transition" dismissible>Service is succesvol bijgewerkt.</v-alert>

          <v-layout align-end justify-end>
            <v-tooltip bottom>
              <span>Service toevoegen</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" @click="createService()" v-if="$session.get('role') == 1" class="mb-2" justify-right style="margin-right:10px;"><font-awesome-icon icon="plus" size="lg"/></v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Service JSON uploaden</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" @click="importService()" v-if="$session.get('role') == 1" class="mb-2" justify-right style="margin-right:10px;"><font-awesome-icon icon="upload" size="lg"/></v-btn>
              </template>
            </v-tooltip>
          </v-layout>

          <v-dialog v-model="importDialog" width="70%">
            <v-form ref="importForm" v-model="valid">
              <v-card>
                <v-card-title>Service importeren</v-card-title>
                <v-card-text>
                  <v-row v-if="importDialog">
                    <v-col cols="12" sm="4" md="4">
                      <input type="file" @change="importJson($event)" accept="application/JSON" required>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="saveJson()">Opslaan</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

          <v-dialog v-model="editDialog" width="800">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>Service wijzigen</v-card-title>
            <v-card-text>
              <!-- <v-container>
                <v-row>
                  <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition">
                    Er deed zich een onverwachte fout voor. Probeer het later opnieuw.
                    </v-alert>
                  <v-col cols="12" sm="6" md="6">
                    <v-select v-model="editedAppLabel" :rules="selectRules" label="AppEUI label" :items="macadresses.map(m => m.label)" required></v-select>
                  </v-col>
                </v-row>
              </v-container> -->
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-row v-if="editDialog"
                    style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field v-model="editedService.name" :rules="serviceNameRules" outlined label="Name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedService.appeuilabel" :rules="selectRules" label="AppEUI label" :items="macadresses.map(m => m.label)" required></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header style="padding:8px 24px;">Parameters</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card elevation="2" v-for="(parameter, parIndex) in editedService.content.Parameters" :key="parIndex" style="margin-bottom:10px">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="parameter.Name" label="Name" ></v-text-field>
                          </v-col>  
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="parameter.Type" label="Type" ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="parameter.Value" label="Default value" ></v-text-field>
                          </v-col>           
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="parameter.Configurable" label="Configurable" ></v-text-field>
                          </v-col>  
                        </v-row>
                        <v-row>
                          <v-card v-if="parameter.Restrictions">
                            <v-card-title>Restrictions</v-card-title>
                            <v-card-text>
                              <v-col v-for="(value, key, index) in parameter.Restrictions" :key="`${ key }-${ index }`">
                                <!-- <p>{{ key }} - {{ value }}</p> -->
                                <v-text-field :label="key" :value="value" ></v-text-field>
                              </v-col>
                            </v-card-text>
                          </v-card>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="processEditService()">Bijwerken</v-btn>
              <v-btn color="primary" text style="margin-left: 10px;" @click="editDialog = false">Sluiten</v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="showDialog" width="70%">
            <v-form v-model="valid">
              <v-card>
                <v-card-title>Hpp Template</v-card-title>
                <v-card-text>
                  <v-row v-if="showDialog">
                    <v-col cols="12" sm="6" md="12">
                      <v-textarea readonly auto-grow v-model="shownTemplateString"> </v-textarea>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeTemplates()">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

          <!-- Create service -->
          <v-dialog v-model="createDialog" width="70%">
            <v-form ref="createForm" v-model="valid">
              <v-card>
                <v-card-title>Service aanmaken</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="paramcol">
                      <v-text-field v-model="createdService.name" label="Service name" class="paramtextfield" dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="paramcol">
                      <v-select v-model="createdService.type" label="Service type" class="paramtextfield" :items="serviceTypes" dense></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="paramcol">
                      <v-select v-model="createdService.supportsRamWipe" label="Supports RAM wipe" class="paramtextfield" :items="[true, false]" dense></v-select>
                    </v-col>
                  </v-row>

                  <!-- Setup v-model for parameters -->
                  <v-row v-for="(parameter, i) in parameters" :key="i" align="center" class="text-fields-row">
                    <v-col cols="12" sm="6" md="3" class="paramcol">
                      <v-text-field :label="parameter.labelName" v-model="parameter.Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" class="paramcol">
                      <v-select :label="parameter.labelType" :items="parameter.Type"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="paramcol">
                      <v-text-field :label="parameter.labelInput" v-model="parameter.Input"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" class="paramcol">
                      <v-select :label="parameter.labelConfigurable" :items="parameter.Configurable"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                      <v-btn @click="addChild(i)" fab dark small color="primary"><font-awesome-icon icon="level-down-alt"/></v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                      <v-btn @click="addRestrictions(i)" fab dark small color="primary"><font-awesome-icon icon="key"/></v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                      <v-btn @click="removeParameter(i)" fab dark small color="primary"><font-awesome-icon icon="minus"/></v-btn>
                    </v-col>

                    <!-- Setup v-model for children -->
                    <v-row v-for="(child, j) in childrenForParam(i)" :key="j" align="center" class="text-fields-row">
                      <v-col cols="12" sm="6" md="1" class="paramcol"></v-col>
                      <v-col cols="12" sm="6" md="3" class="paramcol">
                        <v-text-field :label="parameter.labelName" v-model="child.Name"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" class="paramcol">
                        <v-select :label="parameter.labelType" :items="child.Type"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="paramcol">
                        <v-text-field :label="parameter.labelInput" v-model="child.Input"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" class="paramcol">
                        <v-select :label="parameter.labelConfigurable" :items="child.Configurable"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                        <v-btn @click="addRestrictions(j)" fab dark small color="primary"><font-awesome-icon icon="key"/></v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                        <v-btn @click="removeChild(j)" fab dark x-small color="secondary"><font-awesome-icon icon="minus"/></v-btn>
                      </v-col>

                      <!-- Setup v-model for child restrictions -->
                      <v-row v-for="(childRestriction, k) in restrictionsForChild(j)" :key="k" align="center" class="text-fields-row">
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="UseCheckbox" v-model="childRestriction.UseCheckbox"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="UseSlider" v-model="childRestriction.UseSlider"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="SliderMinimum" v-model="childRestriction.SliderMinimum"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="SliderMaximum" v-model="childRestriction.SliderMaximum"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                          <v-btn @click="removeChildRestrictions(j)" fab dark small color="primary"><font-awesome-icon icon="minus"/></v-btn>
                        </v-col>
                      </v-row>
                    </v-row>

                    <!-- Setup v-model for parameter restrictions -->
                      <v-row v-for="(paramRestriction, l) in restrictionsForParam(i)" :key="l" align="center" class="text-fields-row">
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="UseCheckbox" v-model="paramRestriction.UseCheckbox"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="UseSlider" v-model="paramRestriction.UseSlider"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="SliderMinimum" v-model="paramRestriction.SliderMinimum"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="paramcol">
                          <v-text-field label="SliderMaximum" v-model="paramRestriction.SliderMaximum"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="1" class="paramcol">                                       
                          <v-btn @click="removeParamRestrictions(i)" fab dark small color="primary"><font-awesome-icon icon="minus"/></v-btn>
                        </v-col>
                      </v-row>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="processCreateService()">Opslaan</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

          <v-card>
            <v-card-title>Services<v-spacer></v-spacer><v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" single-line hide-details></v-text-field></v-card-title>
            <v-data-table :headers="headers" :items="services" :search="search" sort-by="name" multi-sort :single-expand="singleExpand" :expanded.sync="expanded" show-expand class="elevation-1">
              <template v-slot:[`item.action`]="service">
                <v-icon v-if="service.item.content.Type == 'DataService' && $session.get('role') == 1" small @click="deleteService(service)">delete</v-icon>
                <v-icon v-if="service.item.content.Type == 'DataService'" v-bind="attrs" v-on="on" small @click="showTemplates(service)">code</v-icon>
                <v-icon v-if="service.item.content.Type == 'DataService' && $session.get('role') == 1" v-bind="attrs" v-on="on" small @click="editService(service)">edit</v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td v-if="item && item.content" :colspan="headers.length">
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-row style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                        <v-col cols="4" sm="12" md="6">
                          <v-text-field v-model="item.content.Name" label="Name" readonly></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                        <v-col cols="4" sm="12" md="6">
                          <v-text-field v-model="item.content.Type" label="Type" readonly></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                        <v-col cols="4" sm="12" md="6">
                          <v-text-field v-model="item.content.SupportsRamWipe" label="Supports RAM wipe" readonly></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:8px 24px;">Parameters</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card elevation="2" v-for="(parameter, parIndex) in item.content.Parameters" :key="parIndex" style="margin-bottom:10px">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="parameter.Name" label="Name" readonly></v-text-field>
                              </v-col>  
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="parameter.Type" label="Type" readonly></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="parameter.Value" label="Default value" readonly></v-text-field>
                              </v-col>           
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="parameter.Configurable" label="Configurable" readonly></v-text-field>
                              </v-col>  
                            </v-row>
                            <v-row>
                              <v-card v-if="parameter.Restrictions">
                                <v-card-title>Restrictions</v-card-title>
                                <v-card-text>
                                  <v-col v-for="(value, key, index) in parameter.Restrictions" :key="`${ key }-${ index }`">
                                    <!-- <p>{{ key }} - {{ value }}</p> -->
                                    <v-text-field :label="key" :value="value" readonly></v-text-field>
                                  </v-col>
                                </v-card-text>
                              </v-card>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

import axios from "axios";

function calcParamSize(param) {
  switch(param.Type) {
    case "Bool":
    case "Uint8":
    case "Int8":
    return 1
    case "Uint16":
    case "Int16":
    return 2
    case "Uint32":
    case "Int32":
    case "float":
    case "Float":
    return 4
    case "Double":
    return 8
    case "Array":
    case "BitField":
    return param.ArrayLength
  }
}

function getCType(param) {
  switch(param.Type) {
    case "Bool":
      return "bool"
    case "Uint8":
      return "uint8_t"
    case "Int8":
      return "int8_t"
    case "Uint16":
      return "uint16_t"
    case "Int16":
      return "int16_t"
    case "Uint32":
      return "uint32_t"
    case "Int32":
      return "int32_t"
    case "float":
    case "Float":
      return "float"
    case "Double":
      case "double":
    case "Array":
      return "uint8_t*"
  }
}

export default {
  name: "Overview",
  data() {
    return {
      importDialog: false,
      createDialog: false,
      showDialog: false,
      editedService: {
        appeuilabel: '',
        content: {
          Name: '',
          Parameters: [],
          SupportsRamWipe: false,
          Type: ''
        },
        id: 0,
        name: '',
        sticker_code: '',
        timestamp: '',
        type: ''
      },
      serviceEdited: false,
      editedAppLabel: "",
      macadresses: [] ,
      shownTemplateString: "",
      expanded: [],
      singleExpand: false,
      search: '',
      drawer: null,
      unknownErrorAlert: false,
      notFoundAlert: false,
      admin: false,
      parameters: [],
      children: [],
      services: [],
      restrictions: [],
      serviceTypes: [
        "BasicCommunicationService", 
        "IntervalCommunicationService", 
        "ContinuousCommunicationService", 
        "DataService", 
        "SleepService", 
        "TimerService"
      ],
      modifyService: {
        name: '',
        appLabel: '',
        content: null
      },
      insertDialog: false,
      editDialog: false,
      item: null,
      serviceImportedAlert: false,
      serviceImportFailedAlert: false,
      serviceJSONFailedAlert: false,
      importedService: {
        name: '',
        type: '',
        content: null,
      },
      createdService: {
        $schema: "Schema.json",
        Name: '',
        Type: '',
        SupportsRamWipe: '',
      },
      headers: [
        { text: "ID", align: "left", value: "id" },
        { text: "Naam", align: "left", value: "name" },
        { text: "Type", value: "type" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Acties", value: "action", sortable: false }
      ],
      serviceNameRules: [
        v => {
          if(v == null) {return 'Veld mag niet leeg zijn'} 
          if(v.length == 0) {return 'Veld mag niet leeg zijn'}
          if(String(v).includes(" ")){return `Er mogen geen spaties in de naam zitten`}
          return true
        }
      ],
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get services on initial page load
    this.$nextTick(this.getServices());

    if (this.$session.get("role") != 1) {
      // Remove userid & actions columns if user isn't admin
      this.headers.splice(-2, 1);
    }

    this.addParameter()
  },
  methods: {
    getServices: function() {
      // Execute GET request to retrieve services for user matching authToken
      axios
        .get(this.$API_LINK+"/retrieve/services/0")
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            // Set services variable to result data
            let tempData = res.data
            tempData.forEach(service => {
              service.type = this.decodeServiceType(service.type)
            });

            this.services = tempData;
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.notFoundAlert = false
            },10000)
          } else {
            console.log(error)
          }
        });
    },
    getAppEuis: function() {
      // Execute GET request to retrieve mac-adresses
      axios
      .get(this.$API_LINK+"/retrieve/mac/appeui")
      .then(res => {
        if (res.status == 200) {
          // If the retrieval was successful
          // Set macadresses variable to result data
          this.macadresses = res.data
        }
      })
      .catch(error => {
        if (error.status == 500) {
          this.unknownErrorAlert = true
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
        }
      })
    },
    deleteService: function(service) {
      if (confirm("Weet u zeker dat u deze service wilt verwijderen?")) {
        const params = {
          id: service.item.id
        };

        // Perform DELETE request to delete user
        axios
          .post(this.$API_LINK+"/delete/service", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.getServices()
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.unknownErrorAlert = false
              },10000)
            }
          });
      }
    },
    decodeServiceType: function(type) {
      let serviceName = ""
      switch(type) {
        case 1:
          serviceName = "BasicCommunicationService"
          break
        case 2:
          serviceName = "IntervalCommunicationService"
          break
        case 3:
          serviceName = "ContinuousCommunicationService"
          break
        case 4:
          serviceName = "DataService"
          break
        case 5:
          serviceName = "SleepService"
          break
        case 6:
          serviceName = "TimerService"
          break
        default:
          serviceName = "Unknown"
          break
      }
      return serviceName
    },
    encodeServiceType: function(type) {
      switch(type) {
        case "BasicCommunicationService":
          return 1 
        case "IntervalCommunicationService":
          return 2 
        case "ContinuousCommunicationService":
          return 3
        case "DataService":
          return 4
        case "SleepService":
          return 5
        case "TimerService":
          return 6
        default:
          return 0
      }
    },
    validate: function() {
      if (this.$refs.insertForm.validate()) {
        this.processInsertDevice();
      }
    },
    decodeBitField: function(bitfield) {
      let bitStr = ""

      if(Array.isArray(bitfield)) {
        bitfield.forEach(function(x) {
          if(x) {
            bitStr = (bitStr + "1")
          } else {
            bitStr = (bitStr + "0")
          }
        })
      }
      
      return parseInt(bitStr, 2)
    },
    importService: function() {
      this.importDialog = true
    },
    editService: function(service) {
      this.getAppEuis()
      this.serviceEdited = false
      console.log(service)
      this.editedService = service.item
      this.editedService.appeuilabel = service.item.appeuilabel
      this.editDialog = true;
      console.log(this.editedService)
    },
    processEditService: function() {

      let newService = {
        id: this.editedService.id,
        content: this.editedService.content,
        name: this.editedService.name,
        timestamp: this.editedService.timestamp,
        type: this.encodeServiceType(this.editedService.type),
        appeuilabel: this.editedService.appeuilabel
      }

      // Perform POST request to insert userpage
      axios
        .post(this.$API_LINK+"/update/service/" + this.editedService.id, newService)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.serviceEdited = true
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.serviceEdited = false
            },10000)

            this.editDialog = false
            this.getServices()
          }
        })
        .catch(error => {
          if (error.response.status == 500) {
            // Handle 404
            this.userpageEditFailedAlert = true
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.userpageEditFailedAlert = false
            },10000)
          }
        });
    },
    showTemplates: function(service) {
      this.showDialog = true
      var pointer = 0
      var resultString = "#pragma region FlashParameters \n"
      service.item.content.Parameters.forEach(param => {
              let cType = getCType(param)
              resultString += cType + " Get"+param.Name+"(){ return ReadFlashParameter<"+cType+">("+pointer+"); }\n"
              resultString += "void Set"+param.Name+"("+cType+" value){ WriteFlashParameter<"+cType+">(value, "+pointer+"); }\n"
              pointer += calcParamSize(param)
            });
            resultString += service.item.name+"() : " + service.item.type + "("+pointer+") {}\n"
            resultString += "#pragma endregion \n"

            this.shownTemplateString = resultString;

            console.log(resultString)
    },
    closeTemplates: function() {
      this.showDialog = false
    },
    createService: function() {
      this.createDialog = true
    },
    importJson: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },
    readFile: function(file) {
      let reader = new FileReader()
      let _this = this

      reader.onload = function(e) {
          // Set importedservice
          let parsedResult = null
          try{
            console.log(parsedResult)
            parsedResult = JSON.parse(e.target.result)
            console.log(parsedResult)
          }
          catch(error) {
            _this.serviceJSONFailedAlert = true
            _this.importDialog = false
            // Automatically close alert after 10s
              setTimeout(()=>{
                _this.serviceJSONFailedAlert = false
              },10000)

              return;

          }
          _this.importedService.name = parsedResult.Name
          _this.importedService.type = parsedResult.Type
          _this.importedService.content = parsedResult
      }

     // Read the file
     reader.readAsText(file);
   },
   saveJson: function() {
     if(this.importedService.name === '' || this.importedService.type === '' || this.importedService.content === null) {
       return;
     }
     axios
      .post(this.$API_LINK+"/store/service", this.importedService)
      .then(res => {
        if (res.status == 200) {
          // If the retrieval was successful
          // Update services
          this.getServices()

          // Set alert
          this.serviceImportedAlert = true
          // Automatically close alert after 10s
          setTimeout(()=>{
            this.serviceImportedAlert = false
          },10000)

          this.importDialog = false

        }
      })
      .catch(error => {
        if (error.status == 500) {
          // Handle 500
          this.serviceImportFailedAlert = true
          // Automatically close alert after 10s
          setTimeout(()=>{
            this.serviceImportFailedAlert = false
          },10000)
          
          this.importDialog = false

        } else {
          this.serviceImportFailedAlert = true
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.serviceImportFailedAlert = false
            },10000)
          
          this.importDialog = false
        }
      });
    },
    processCreateService: function() {
      let service = this.createdService

      service.parameters = this.parameters
      service.parameters.push(this.children)
      console.log(this.parameters)
      console.log(this.children)
      // debug createdservice object
      console.log(service)
    },
    addParameter: function(index) {
      // Initialize parameter
      let parameter = {
        labelName: "Name", 
        Name: "",
        labelType: "Type",
        Type: [
          "Bool",
          "Uint8",
          "Int8",
          "Uint16",
          "Int16",
          "Uint32",
          "Int32",
          "Uint64",
          "Int64",
          "Float",
          "Double",
          "BitField",
          "Array",
          ],
        labelInput: "Default value",
        Value: "",
        labelConfigurable: "Configurable",
        Configurable: [true, false]
      }

      if(index != null) {
        // Add parentName if specified
        parameter.ParentIndex = index
        this.children.push(parameter)
      } else {
        this.parameters.push(parameter)
      }
    },
    addChild: function(index) {
      this.addParameter(index)
    },
    removeParameter: function(index) {
      this.parameters.splice(index, 1)
    },
    childrenForParam: function(index) {
      return this.children.filter(c => c.ParentIndex == index)
    },
    addRestrictions: function(index) {
      let restrictionSet = {
        ParentIndex: index,
        UseCheckbox: false,
        UseSlider: false,
        SliderMinimum: 0,
        SliderMaximum: 15,
      }

      this.restrictions.push(restrictionSet)
    },
    removeRestrictions: function(index) {
      this.restrictions.splice(index, 1)
    },
    restrictionsForParam: function(index) {
      return this.restrictions.filter(r => r.ParentIndex == index)
    },
    restrictionsForChild: function(index) {
      return this.restrictions.filter(r => r.ChildIndex == index)
    },
  },
  
};
</script>
<style>
  .paramtextfield v-text-field {
    padding: 0 !important;
  }

  .paramtextfield input {
    padding: 0 !important;
  }

  .paramcol {
    padding: 0px 16px;
  }
</style>