<template>
  <div id="macadresses">
    <v-container fluid>
      <v-layout align-end justify-end>
        <v-tooltip bottom>
          <span>Mac-adres registreren</span>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" @click="generateDialog = true" v-if="$session.get('role') == 1" dark class="mb-2" justify-right style="margin-right:10px;"><font-awesome-icon icon="plus" size="lg"/></v-btn>
          </template>
        </v-tooltip>
      </v-layout>
      <v-layout align-center justify-center text-xs-center>
        <div text-xs-center style="width:100%">
          <v-alert :value="macGeneratedAlert" type="success" transition="fade-transition" dismissible>Mac-adres is succesvol gegenereerd.</v-alert>
          <v-alert :value="inputIncompleteAlert" type="warning" transition="fade-transition" dismissible>Invoer is incompleet.</v-alert>
          <v-alert :value="macFailedAlert" type="error" transition="fade-transition" dismissible>Aanmaken van mac-adres is mislukt. {{ macFailedMessage }}</v-alert>
          <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition" dismissible>Er heeft zich een onbekende fout voorgedaan.</v-alert>
          <v-alert :value="retrieveFailedAlert" type="error" transition="fade-transition" dismissible>Ophalen van mac-adres is mislukt.</v-alert>
          <v-alert :value="macFullAlert" type="error" transition="fade-transition" dismissible>Maximale grootte mac-adres is gebruikt (FFFFFFF).</v-alert>
        
          <v-card>
            <v-card-title>Mac-adressen<v-spacer></v-spacer><v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" single-line hide-details></v-text-field></v-card-title>
            <v-data-table 
            :headers="headers" 
            :items="macadresses" 
            :search="search" 
            :footer-props="{
              'items-per-page-options': [50, 100, -1]
            }"
            multi-sort 
            class="elevation-1">
              <template v-slot:[`item.action`]="item" v-if="$session.get('role') == 1">
                <v-icon small class="mr-2" @click="editMac(item)">edit</v-icon>
                <v-icon small @click="deletAppEui(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-layout>
    </v-container>

    <v-dialog v-model="generateDialog" width="70%">
      <v-form ref="generateForm" v-model="valid">
        <v-card>
          <v-card-title>Mac-adres registreren</v-card-title>
          <v-card-text>
            <v-row v-if="generateDialog">
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="macItem.address" label="Mac-adres" :rules="macRules" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="macItem.label" label="Label"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <!-- <v-select v-model="macItem.type" label="Type" :items="['DevEUI','AppEUI']" :rules="selectRules" required></v-select> -->
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-if="macItem.type == 'DevEUI'" v-model="macItem.parent" label="Te koppelen AppEUI" hint="Leeglaten indien onnodig"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="validateGenerateMac()">Registreren</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="editDialog" width="70%">
      <v-form ref="editForm" v-model="valid">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>Mac-adres wijzigen</v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="editDialog">
                <v-col v-if="editedMac.type == 'DevEUI'" cols="12" sm="4" md="4">
                  <v-text-field v-model="editedMac.appeui" label="Application EUI"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="editedMac.label" label="Label"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="validateEditMac()">Bijwerken</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "Overview",
  data() {
    return {
      macGeneratedAlert: false,
      inputIncompleteAlert: false,
      macFailedAlert: false,
      macFailedMessage: "",
      unknownErrorAlert: false,
      retrieveFailedAlert: false,
      macFullAlert: false,
      generateDialog: false,
      editDialog: false,
      valid: true,
      loadInsertBulk: false,
      entries: [],
      isLoading: false,
      searchAppEui: null,
      bufferedEuis: [],
      macItem: {
        address: null,
        label: null,
        type: null,
        parent: null,
      },
      editedMac: {
        appeui: null,
        address: null,
        label: null,
        type: null,
      },
      bulkItem: {
        appEui: null,
        appEuiLabel: null,
        devEuiCount: 0,
        devEuiLabel: null,
      },
      macRules: [
        v => {
          if(!v) {return 'Veld mag niet leeg zijn null'} 
          if(v.length == 0) {return 'Veld mag niet leeg zijn 0'}
          if(!String(v).replaceAll(" ", "").match("^([0-9A-Fa-f]{16})$")){return `Deze EUI is niet geldig`}
          return true
        }
      ],
      appEuiRules: [
        v => !!v || 'AppEUI mag niet leeg zijn'
      ],
      selectRules: [
        v => !!v || 'Selectie mag niet leeg zijn'
      ],
      euiCountRules: [
        v => v > 0 || 'Aantal DevEUIs mag niet minder dan 1 zijn'
      ],
      headers: [
        { text: "ID", align: "left", value: "id" },
        { text: "AppEUI", align: "left", value: "address" },
        { text: "Label", align: "left", value: "label" },
        { text: "Acties", value: "action", sortable: false }
      ],
      macadresses: [],
      expanded: [],
      singleExpand: false,
      search: "",
    };
  },
  computed: {
    fields () {
      if (!this.bulkItem.appEui) return []

      return Object.keys(this.bulkItem.appEui).map(key => {
        return {
          key,
          value: this.bulkItem.appEui[key] || 'n/a',
        }
      })
    },
    items () {
      return this.entries.map(entry => {
        let Label = entry.label

        if(entry.label == "") {
          Label = entry.address
        }        
  
        return Object.assign({}, entry, { Label })
      })
    },
  },
  watch: {
    searchAppEui (val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      fetch(this.$API_LINK+"/retrieve/mac/appeui")
        .then(res => res.json())
        .then(res => {
          this.entries = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },

  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    this.getAppEuis()
  },
  methods: {
    validateGenerateMac: function() {
      if(this.$refs.generateForm.validate()) {
        this.processGenerateMac()
      }
    },
    processGenerateMac: function() {
      this.macFailedAlert = false
      // Validate form data
      if(this.macItem.address != null && this.macItem.label != null) {
        this.macItem.type = "AppEUI"
        // POST request
        axios.post(this.$API_LINK+"/generate/mac/confirm", this.macItem)
        .then(res => {
          if (res.status == 200) {
            // Show success alert
            this.macGeneratedAlert = true
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.macGeneratedAlert = false
            },10000)

            this.generateDialog = false

            // Get new mac
            this.getAppEuis()
          }
        })
        .catch(error => {
          // Show error alerts
          if (error.response.status == 400) {
            console.log("400 bad request")
            this.macFailedAlert = true
            // Automatically close alert after 10s
            this.macFailedMessage = error.response.data

            this.generateDialog = false
          } else {
            console.log(error.status)
            this.unknownErrorAlert = true
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)

            this.generateDialog = false
          }
        }) 
      } else {
        // Show incomplete alert
        this.inputIncompleteAlert = true
        // Automatically close alert after 10s
            setTimeout(()=>{
              this.inputIncompleteAlert = false
            },10000)

        this.generateDialog = false
      }
    },
    getAppEuis: function() {
      // Execute GET request to retrieve mac-adresses
      axios
      .get(this.$API_LINK+"/retrieve/mac/appeui")
      .then(res => {
        if (res.status == 200) {
          // If the retrieval was successful
          // Set macadresses variable to result data
          this.macadresses = res.data
        }
      })
      .catch(error => {
        if (error.status == 500) {
          this.unknownErrorAlert = true
          // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
        }
      })
    },
    deletAppEui: function(appeui) {
      if (confirm("Weet u zeker dat u dit mac-adres wilt verwijderen?")) {
        const params = {
          id: appeui.item.id
        };

        // Perform POST request to delete user
        axios
          .post(this.$API_LINK+"/delete/mac", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.getAppEuis()
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(()=>{
                this.unknownErrorAlert = false
              },10000)
            }
          });
      }
    },
    validateEditMac: function() {
      if(this.$refs.editForm.validate()) {
        this.processEditMac()
      }
    },
    editMac: function(mac) {
      // Set selected user data to the editDialog form
      this.editedMac = mac.item;
      console.log(this.macadresses)
      // Set editDialog to visible
      this.editDialog = true;
    },
    processEditMac: function() {
      // Post values to api
      axios
        .post(this.$API_LINK+"/update/mac", this.editedMac)
        .then(res => {
          if (res.status == 200) {
            // Set editDialog to invisible
            this.editDialog = false;

            // Re-fetch adresses
            this.getAppEuis()
          }
        })
        .catch(error => {
          if (error.response.status == 500) {
            // Handle 401
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
          }
        });

      // Set editDialog to invisible
      this.editDialog = false;
    },
  }
};
</script>
