<template>
  <div id="overview">
    <v-container fluid>
      <v-card>
        <v-card-title>Gegevens verwijderen</v-card-title>
        <v-card-text>
            <v-alert :value="unknownErrorAlert" type="error" transition="fade-transition" dismissible>
                Er deed zich een onverwachte fout voor. Probeer het later opnieuw.
            </v-alert>
            <v-alert :value="deletedAlert" type="success" transition="fade-transition">
                Accountsgegevens zijn verwijderd. Klik <a @click="logout()">hier</a> om uit te loggen.
            </v-alert>
            <v-alert :value="networkErrorAlert" type="warning" transition="fade-transition" dismissible>
                Er deed zich een netwerkfout voor. Probeer het later opnieuw.
            </v-alert>
            <v-alert :value="alreadyDeletedAlert" type="warning" transition="fade-transition" dismissible>
                Uw account is reeds verwijderd. Klik <a @click="logout()">hier</a> om uit te loggen.
            </v-alert>
            <p>Door op onderstaande knop te klikken verwijdert u al uw bij ons opgeslagen persoonsgegevens.</p>
            <v-btn color="primary" @click="deleteAccount()">Verwijderen</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      unknownErrorAlert: false,
      networkErrorAlert: false,
      deletedAlert: false,
      alreadyDeletedAlert: false
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
  },
  methods: {
    deleteAccount: function() {
      if(confirm("Weet u zeker dat u uw account wilt verwijderen?")) {
        const params = {
          token: this.$session.get("authToken")
        }

        axios.post(this.$API_LINK+"/delete/account", params)
        .then(res => {
          if (res.status == 200) {
            this.deletedAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.deletedAlert = false
            },10000)
          }
        })
        .catch(error => {
          if (!error.response) {
            // Network error
            this.networkErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.networkErrorAlert = false
            },10000)
          } else if (error.response.status == 500) {
            // Handle 500 internal server error
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(()=>{
              this.unknownErrorAlert = false
            },10000)
          }
        })
      }
    },
  }
};
</script>
